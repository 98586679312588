import React, { useEffect, useState } from 'react';
// import { setChanges } from "../pages/reducers";
import Footer from '../Components/Footer';
import Navbar from '../Components/navbar';
import Secbar2 from '../Components/secbar2';
import { useNavigate } from "react-router-dom";
import {useSelector,useDispatch} from 'react-redux'
import UpdateUserPopup from '../Components/updateUserPopup';
import {convertToArrayofObjects} from '../Components/datamangers'
import ConfirmPopup3 from '../Components/confirmPopup3';
import {toast} from "react-toastify"
import { BASE_API_URL, UPLOAD_IMAGE_URL, UPLOAD_RESOURCES_URL,GET_DOCUMENTS_URL } from '../apiEndpoints';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableRows from "../pages/tableRow";
import DeleteIcon from "@mui/icons-material/Delete";

const Edit_Initiative=(props)=>{
    let AuthTokens=useSelector(state=>state.AuthTokens)
    let dispatch=useDispatch()
  const currentInit = useSelector((state) => state.currentInit);

    const initToBeEdited=useSelector(state=>state.editInitData)
    let [iName,setIname]=useState(initToBeEdited.project_title)
    let [initID, setInitID] = useState(initToBeEdited.id)
    const [Resources, setResources] = useState([]);
    const [imageLoaded, setImageLoaded] = useState(true);
    const currentPoint=useSelector(state=>state.isLogged)
    const changes = useSelector(state=>state.view_resources_changes)

    // let Currentuser=JSON.parse(sessionStorage.getItem('logged'))
    let [error,seterror]=useState("")
    const navigate=useNavigate()

    useEffect(() => {
      const getData = async () => {
        let response = await fetch(GET_DOCUMENTS_URL, {
          method: "GET",
        });
        let data = await response.json();
        if (response.status === 200) {
          setResources(data);
        } else {
          console.log("bugged oout");
        }
      };
      getData();
    }, [changes]);

    async function uploadResource(file, project_title, project_id) {
        console.log(initID)
         project_id = initID
        const formData = new FormData();
    formData.append('document', file);
    formData.append('project_title', project_title);
    formData.append('project_id', project_id);
    formData.append('upload_date', new Date().toISOString());
    // console.log(project_id)
    // console.log(project_title)
    // console.log(file)
    try {
      const response = await fetch(UPLOAD_RESOURCES_URL, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      // Update project object with uploaded resource ID
      let updatedProject = {
        id: initID,
        title: iName,
        resource_id: data.resource_id,
      };

      // Make API call to update project object
      const projectResponse = await fetch(
        BASE_API_URL + `/project/${project_id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data", // Set Content-Type header to multipart/form-data
          },
          body: JSON.stringify(updatedProject), // Send formData object instead of JSON.stringify(updatedProject)
        }
      );

      const projectData = await projectResponse.json();

      console.log(projectData);

      // Dispatch the action to update the view changes
      //   window.location.reload();
      // dispatch(set_view_changes())
    } catch (error) {
      console.error(error);
    }
  }

  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      uploadResource(file, props.project_id);
    }
  };

  const handleUploadClick1 = (initID) => {
    const fileInput = document.getElementById(`files-${initID}`);
    fileInput.click();
  };
  const [file, setFile] = useState(null);

  function handleResourceError(e) {
    e.target.onerror = null;
    // e.target.src = defaultImageUrl; // replace this with the url of your default resource
    setImageLoaded(false);
  }

  async function uploadImage(file, project_title, project_id) {
    const formData = new FormData();
formData.append('image', file);
formData.append('project_title', project_title);
formData.append('project_id', project_id)
// console.log(project_id)
try {
  const response = await fetch(UPLOAD_IMAGE_URL, {
    method: 'POST',
    body: formData,
  });

      const data = await response.json();

      // Update project object with uploaded image ID
      let updatedProject = {
        id: initID,
        title: iName,
        image_id: data.image_id,
      };

      // Make API call to update project object
      const projectResponse = await fetch(
        BASE_API_URL + `/project/${project_id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedProject),
        }
      );

      const projectData = await projectResponse.json();

      console.log(projectData);

      // Dispatch the action to update the view changes
      //   window.location.reload();
      // dispatch(set_view_changes())
    } catch (error) {
      console.error(error);
    }
  }

  const handleUploadClick = (initID) => {
    if (file) {
      const fileInput = document.getElementById(`files-${initID}`);
      uploadImage(file, initID);
      toast(<ConfirmPopup3 onBackToInventory={handleBackToInventory} />);
    } else {
      // Display a toast with an error message
      toast.error("Please select a file before updating.");
    }
  };

  // const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      uploadImage(event.target.files[0], props.project_title, initID);
    }
  };



  // Dispatch the action to update the view changes
//   window.location.reload();
    // dispatch(set_view_changes())





function handleImageError(e) {
    e.target.onerror = null;
    // e.target.src = defaultImageUrl; // replace this with the url of your default image
    setImageLoaded(false);
  }
  //   console.log(iName)
  const handleBackToInventory = () => {
    // Perform navigation to the login page
    navigate("/inventory");
  };

  return (
    <div>
      <div>
        <Navbar />
        <Secbar2 />
        <div className="px-8 py-4">
          <h1 className="text-xl font-semibold">Add Image / Resource</h1>
          <div>
            <p className="text-base text-text-dark-grey pt-2">
              Add an Image and or Resource(s) related to an Initiative.<br></br>
            </p>
          </div>
          <br></br>
          <div className="w-1/3 flex flex-col gap-2">
            <div className="text-base">
              <label for="fname">Project Name</label>
            </div>
            <div className="fl-inputs">
              <input
                type="text"
                disabled
                style={{ width: "100%" }}
                id="fname"
                value={iName}
                onChange={(e) => setIname(e.target.value)}
              ></input>
            </div>

            <div className="text-base">
              <div className="flex">
                <label htmlFor="email">Upload Image</label>
              </div>
              <div className="fl-inputs1 flex">
                <form>
                  <input
                    id={`files-${initID}`}
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ height: "100%" }}
                  />
                </form>
              </div>
            </div>

            <div className="text-base">
              <div className="flex">
                <label for="resource">
                  Upload Resources (Max: 3 files, PDF or Word)
                </label>
              </div>
              <div className="fl-inputs1 flex">
                <form>
                  <input
                    id={`files-${initID}`}
                    type="file"
                    style={{ height: "100%" }}
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange1}
                  />
                </form>
              </div>
            </div>
            <div>
              <p className="adduser-error">{error}</p>
              <button
                className="change-u"
                onClick={() => handleUploadClick(initID)}
              >
                Update
              </button>
              <a href="/posted">
                <button className="cancel">Cancel</button>
              </a>
            </div>
            <div>
            
          </div>

          </div>
          <table className="w-full">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="" className="px-4 py-2">
                        Resource Name
                      </TableCell>
                      <TableCell align="" className="py-2">
                        Date Uploaded
                      </TableCell>
                      <TableCell align="" className="py-2">
                        Options
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Resources.map((item) => {
                      if (initID === item.project_id) {
                        let test = item.upload_date.split("T");
                        item.upload_date = test[0];
                        return (
                          <TableRows
                            key={item.id}
                            name={item.name}
                            path={item.document}
                            dates={item.upload_date}
                            showDeleteButton={true}
                          />
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </table>
        </div>
        <Footer />
      </div>
      {/* <UpdateUserPopup/> */}
    </div>
  );
};

export default Edit_Initiative;

import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
const DashItem=({Icon,title,to})=>{
    return(
        <div className="dash-item">
            
            <p  style={{color:"white"}} onClick={()=>{
                return <Navigate to={`/${to}`} replace={true} />
            }}> {title}</p>
        </div>
    )
}
export default DashItem;
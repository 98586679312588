import React, { useState, useMemo,useEffect } from 'react';
import Pagination from '../Components/Pagination';
import axios from "axios";
import { convertToArrayOfObjects2 } from '../Components/datamangers';
import InventoryUpload from '../Components/inventoryUpload';
import {useSelector,useDispatch} from 'react-redux'
import {setNewInits} from '../pages/reducers'
import Navbar from '../Components/navbar';
import Secbar2 from '../Components/secbar2';
import Footer from '../Components/Footer';
import { BASE_API_URL, FILTER_PROJECTS_NAMES_URL, FILTER_PROJECTS_URL, GET_PROJECTS_URL } from '../apiEndpoints';
//import {getInitiatives} from '../pages/posted_initiatives'


let PageSize = 5

export default function TestUpload(props) {
    const [usersArray,setUserArray]=useState([])
    const initsArray=useSelector(state=>state.initsArray)
    const [currentPage, setCurrentPage] = useState(0);
    const AuthTokens=useSelector(state=>state.AuthTokens)
    const changes=useSelector(state=>state.view_inits_changes)
    const dispatch=useDispatch() 
   
  async function getNames(){
  let searchName = document.getElementById('getSearch').value;
  console.log(searchName)
  let test={'init_name':searchName}
  if(searchName==''){
    const getData=async()=>{ 
      let arr=[]
      let response= await fetch(GET_PROJECTS_URL,{
             method:'GET',
         })
         let data=await response.json()
         if(response.status===200){
             setUserArray(data)
             dispatch(setNewInits(data))
         }else{
            console.log("bugged oout")
         }
   }   
  getData()
    
  }
  else{
    const getData=async()=>{ 
      let response= await fetch(FILTER_PROJECTS_NAMES_URL,{
        method:'POST',
        headers:{
            'Content-Type':'application/json',
            // 'Authorization':'Bearer '+ String(AuthTokens.access)
        },
        body:JSON.stringify(test)
        })
         let data=await response.json()
         if(response.status===200){
             setUserArray(data)
             dispatch(setNewInits(data))
             console.log(data)
         }else{
            console.log("bugged oout")
         }
   }   
  getData()
  }
  }
    async function getInitiatives(){
      let status;
      let check=document.getElementById('checkbox-active')
      let check2=document.getElementById('checkbox-ongoing')
      if(check.checked && check2.checked){
        const getData=async()=>{ 
          let response= await fetch(GET_PROJECTS_URL,{
                 method:'GET',
             })
             let data=await response.json()
             if(response.status===200){
                 setUserArray(data)
                 dispatch(setNewInits(data))
             }else{
                console.log("bugged oout")
             }
       }   
      getData()
      }
      else{
        if (check.checked) {
            status = 'ACTIVE'
          
        }
        if(check2.checked){
            status = 'ONGOING'
        }
      let test= {'status':status}
      if(!check.checked && !check2.checked){
        const getData=async()=>{ 
          let response= await fetch(GET_PROJECTS_URL,{
                 method:'GET',
             })
             let data=await response.json()
             if(response.status===200){
                 setUserArray(data)
                 dispatch(setNewInits(data))
             }else{
                console.log("bugged oout")
             }
       }   
      getData()
      }
      else{
        const getData=async()=>{ 
          let response= await fetch(FILTER_PROJECTS_URL,{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                // 'Authorization':'Bearer '+ String(AuthTokens.access)
            },
            body:JSON.stringify(test)
            })
             let data=await response.json()
             if(response.status===200){
              console.log(data)
                 setUserArray(data)
                 dispatch(setNewInits(data))
                 
             }else{
                console.log("bugged oout")
             }
       }   
      getData()
      }
    }
  }
    
    useEffect(()=>{
       const getData=async()=>{ 
        let arr=[]
        let response= await fetch(GET_PROJECTS_URL,{
               method:'GET',
           })
           let data=await response.json()
           if(response.status===200){
               setUserArray(data)
               dispatch(setNewInits(data))
               console.log(data)
           }else{
              console.log("bugged oout")
           }
     }   
    setCurrentPage(1)
    getData()
   },[props.id,changes])
   
    const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return initsArray.slice(firstPageIndex, lastPageIndex);
    },[currentPage,initsArray]);
    
    if(usersArray){
    return ( 
      <>
      
      <Navbar/>
      <Secbar2/>
      <div className="search-container">
        
                  <div>
                    <input type="text" placeholder="Search" id='getSearch' onInput={getNames}></input>
                  </div>
                  
                    <div className = 'test'>
                        <button onClick={myFunction4} className='filter'>Filters </button>
                            <div id="myDropdown4" class="dropdown-content-posted">
                                <h4 class = 'init-status'>Status</h4>
                                <input className='ongoing'type = 'checkbox' id = 'checkbox-active'/><h5 className='name-tag'>COMPLETE</h5>
                                <input className='completed'type = 'checkbox' id='checkbox-ongoing'/><h5 className='final-tag'>ONGOING</h5>
                                <hr className = 'posted-hr'></hr>
                                <div className = 'searchtext'>
                                    <button className='postedcancel'>Cancel</button>
                                    <button onClick={getInitiatives} className = 'postedapply' >Apply</button>
                                </div>
                            </div>
                    </div>
               
                
           
        </div>
  
        <table className = 'inventory-upload'>
            <tr>
                <th className='zero-ref'>Initiative Image</th>
                <th className ='frst-ref'>Ref #</th>
                <th className ='scnd-init'>Initiative Name</th>
                <th className = 'thrd-stat'>Status</th>
                <th className = 'thrd-stat'>Project Resources</th>
            </tr>
            
                  {currentTableData.map(function(user,index){
                  //  console.log(user)http://localhost:8000/api/media/project_25/25.jpg
                      return <InventoryUpload project_img={BASE_API_URL+`/media/project_${user.id}/${user.id}.jpg`} project_id={user.id} project_title={user.project_title} status={user.project_status} color={user.project_status}/>
                  })}
                
          </table>
        <div className = 'pagIstyles'>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={usersArray.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          />
          </div>
         <Footer/>
        </>
    );
    }else{
      return(
          <div>
              <h1>LOADING...</h1>
          </div>
      )
  }
   
  }
  function myFunction4() {
    document.getElementById("myDropdown4").classList.toggle("show");
  }
  
  // Close the dropdown menu if the user clicks outside of it
  window.onclick = function(event) {
    if (!event.target.matches('.filter')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
}
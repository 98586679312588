import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAuthTokens,setIsLoggedIn } from "../../pages/reducers";
import { setUser } from "../../pages/reducers";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useEffect,useState,useRef } from 'react';
// import { DarkButton } from "./MUI_Components/button";


export default function FadeMenu() {
  let navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let dispatch = useDispatch();

  function redirectPage(text){
    window.location.href = text
  }

  const handleLogout = async () => {
    try {
      // Make a request to the Django backend's logout endpoint
      await fetch('/logout/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
  
      // Optionally, you can check the response status here if needed
  
      // Navigate to the home page
      navigate('/', { replace: true });
      // Close the menu
      handleClose();
    } catch (error) {
      console.error('Logout failed:', error);
      // Handle error, show a message, or retry the logout
    }
  };
  
  return (
    <div>
      <Button 
      className="gap-2"
        sx={{ border: "1px solid #D6D6D6", color: "#5E5E5E", textTransform: "none" }}
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <SettingsIcon />
        My Account
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem sx={{fontSize: "14px"}} onClick={() => {window.location.href="/change"}}>Change Password</MenuItem>
        <MenuItem sx={{fontSize: "14px"}} onClick={() => {
              localStorage.removeItem("authTokens");
              dispatch(setAuthTokens(null));
              dispatch(setUser(null));
              dispatch(setIsLoggedIn(false));
              navigate('/', {replace:true})
            }}>Log Out</MenuItem>
      </Menu>
    </div>
  );
}

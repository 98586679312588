import React, { useState, useMemo, useEffect } from "react";
import Pagination from "./Pagination";
import Rows from "./row";
import { useSelector, useDispatch } from "react-redux";
import { setNewusers } from "../pages/reducers";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { GET_USERS_URL } from "../apiEndpoints";
import { toast } from "react-toastify";
import TokenExpirationPopup from "./tokenPopup";
import { useNavigate } from "react-router-dom";
let usersData = [];
let PageSize = 5;

export default function ManageUserTable(props) {
  let dispatch = useDispatch();
  let usersArray = useSelector((state) => state.usersArray);
  let AuthTokens = useSelector((state) => state.AuthTokens);
  const [currentPage, setCurrentPage] = useState(0);
  const changes = useSelector((state) => state.pageChanges);
  const currentEmail = useSelector((state) => state.User).email;
  let navigate=useNavigate()
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return usersArray.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, usersArray]);

  const handleBackToManageUsers = () => {
    // Perform navigation to the login page
    navigate("/login");
  };
  useEffect(() => {
    const getData = async () => {
      let response = await fetch(
        GET_USERS_URL,
        {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(AuthTokens.access),
        },
      });
      let data = await response.json();
      if (response.status === 200) {
        console.log(data);
        dispatch(setNewusers(data));
        setCurrentPage(1);
      } 
      else if(response.status === 401){
        toast(<TokenExpirationPopup onBackToLogin={handleBackToManageUsers}/>)
        navigate('/login',{replace:true})
      }else {
        console.log("bugged out");
      }
    };
    getData();
  }, [props.id, changes]);

  if (usersArray) {
    return (
      <div>
        <div className="">
          <TableContainer component={Paper}> 
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" className="px-4 py-2">
                    Name
                  </TableCell>
                  <TableCell align="left" className="py-2">
                    Email
                  </TableCell>
                  <TableCell align="center" className="py-2">
                    Account Type
                  </TableCell>
                  <TableCell align="center" className="py-2">
                    Campus
                  </TableCell>
                  <TableCell align="center" className="py-2">
                    Status
                  </TableCell>
                  <TableCell align="center" className="py-2">
                    Options
                  </TableCell>
                  <TableCell align="center" className="py-2">
                    {" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {currentTableData.map(function (user, index) {
                  if (user.email !== currentEmail) {
                    return (
                      <Rows 
                        firstname={user.first_name}
                        lastname={user.last_name}
                        email={user.email}
                        campus={user.campus_name}
                        role={user.roles_title}
                        status={user.status}
                        color={user.status}
                      />
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="pagIstyles pt-4">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={usersArray.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h1>LOADING...</h1>
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TokenExpirationPopup from "../Components/tokenPopup";
import logoutUser from "./logoutUser";
import { setIsLoggedIn } from "../pages/reducers";

const withTokenExpiration = (WrappedComponent) => {
  return function TokenExpirationWrapper(props) {
    const dispatch = useDispatch();
    const isAuthenicated = useSelector((state) => state.User);
    const logged = useSelector((state) => state.isLoggedIn);
    const navigate = useNavigate();

    const [showInactivityPopup, setShowInactivityPopup] = useState(false);

    useEffect(() => {
      let inactivityTimer;
      const inactivityDuration =  60 * 1000; // 30 minutes in milliseconds

      const handleInactivity = () => {
        setShowInactivityPopup(true);
        logoutUser();
        localStorage.removeItem("authTokens");
      };

      const resetInactivityTimer = () => {
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(handleInactivity, inactivityDuration);
      };

      resetInactivityTimer();

      const handleUserActivity = () => {
        resetInactivityTimer();
      };

      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);

      return () => {
        clearTimeout(inactivityTimer);
        window.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("keydown", handleUserActivity);
      };
    }, []);

    useEffect(() => {
      if (showInactivityPopup) {
        // Display the inactivity popup when showInactivityPopup is true
        {logged && toast(<TokenExpirationPopup onBackToLogin={handleBackToLogin} />)};
      }
    }, [showInactivityPopup,logged]);

    const handleBackToLogin = () => {
      // Perform navigation to the login page
      setShowInactivityPopup(false);
      dispatch(setIsLoggedIn(false));
      navigate("/login",{replace:true})
    };

    return (
      <div>
        <WrappedComponent {...props} />
      </div>
    );
  };
};

export default withTokenExpiration;

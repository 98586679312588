import React from "react";

export default function PopupRows() {
    
  return (
<div id = 'helper'>
<div className = 'alertContainer'>
    <div id="popUpOverlay">
      <div id="popUpBox">
      <p className = 'popUpText'>
        User account status has been changed.
      </p>
      <button className = 'alert_button' onClick={()=>removeComp()}
      > 
      OK
      </button>
      </div>
    </div>
    </div>
    </div>
  );
  
}
function removeComp(){
    document.getElementById('helper').style.display = 'none';
}
import React from "react";
import { useSelector } from "react-redux";

export default function FilterPopup({closeToast }) {
  const filterCounting=useSelector(state=>state.filterCount)
  const handleClose = () => {
    closeToast();
  };

  return (
    <div>
      <div className="">
        <div id="">
          <div id="">
            {filterCounting > 1 ? <p className="popUpText"> {filterCounting} projects found.</p> : (<p className="popUpText"> {filterCounting} project(s) found.</p>)}
            <button className="alert_button" onClick={handleClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { useState } from "react";
import exportFromJSON from "export-from-json";
import { useDispatch, useSelector } from "react-redux";
import { setProjectID, set_view_changes } from "../pages/reducers";
import { setInitData } from "../pages/reducers";
import { useNavigate } from "react-router-dom";
import { editInitData } from "../pages/reducers";
import DeletePopUp2 from "./deleteInitPopup";
import { ProjectID } from "../pages/reducers";
import DeleteInitPopup from "./deleteInitPopup";
import { toast } from "react-toastify";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ADD_PROJECT_URL,
  DELETE_PROJECT_URL,
  GET_INITIATIVE_DATA_URL,
} from "../apiEndpoints";
import RemoveFromMapPopUp from "./removedFromMapPopup";
import DownloadCSV from "./downloadCSVPopup";
import TokenExpirationPopup from "../Components/tokenPopup";


var XLSX = require("xlsx");
const RowsUpload = (props) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const handleBackToLogin = () => {
    // Perform navigation to the login page
    navigate("/login");
  };
  const initToBeEdited = useSelector((state) => state.editInitData);
  let [iName, setIname] = useState(props.project_title);
  let AuthTokens = useSelector((state) => state.AuthTokens);
  let timer = localStorage.getItem('expirationTime')

  function deleteInit(project_id) {
    let test = { project_id: project_id };
    console.log(test)
    const getData = async () => {
      let arr = [];
      let response = await fetch(DELETE_PROJECT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(AuthTokens.access),
        },
        body: JSON.stringify(test),
      });
      let data = await response.json();
      if (response.status === 200 && timer > 0) {
        console.log(data);
        dispatch(set_view_changes());
        toast(<RemoveFromMapPopUp action="remove" />);
      } else if(response.status === 401) {
        toast(<TokenExpirationPopup onBackToLogin={handleBackToLogin}/>)
        navigate('/login',{replace:true})
      }
    };
    getData();
  }

  function addInit(project_id) {
    let test = { project_id: project_id };
    const getData = async () => {
      let arr = [];
      let response = await fetch(ADD_PROJECT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(AuthTokens.access),
        },
        body: JSON.stringify(test),
      });
      let data = await response.json();
      if (response.status === 200) {
        console.log(data);
        dispatch(set_view_changes());
      } else {
        console.log("bugged oout");
      }
    };
    getData();
  }
  
  function editInit(data) {
    dispatch(setInitData(data));
  }
  return (
    <>
      <tr id="text-base" className="text-base gap-4">
        <td className="frst-ref">
          <a className="mng" href="">
            {props.project_id}
          </a>
        </td>
        <td className="scnd-init py-4">{props.project_title}</td>
        <td className="text-center py-4">
          <button className={props.color}>{props.status}</button>
        </td>
        <td className="thrd-stat pl-2">
          <div className="row-upload-download-del-div">
            <button
              class="download-csv"
              onClick={() => {
                downloadIniative(props.project_id);
                toast.success(<DownloadCSV />);
              }}
            >
              Download CSV
            </button>
            {props.available == true ? (
              <button
                class="delete-csv"
                onClick={() => {
                  deleteInit(props.project_id);
                }}
              >
                Remove from Map
              </button>
            ) : (
              <button
                className="add-csv"
                onClick={() => {
                  addInit(props.project_id);
                  toast(<RemoveFromMapPopUp action="add" />);
                }}
              >
                {" "}
                Add to Map
              </button>
            )}


<EditNoteIcon
              className="cursor-pointer"
              title="Edit User"
              onClick={() => {
                let data = props.data;
                dispatch(setInitData(data));
                navigate("/edit_initiative", { replace: true });
              }}
            />
          
            
              <DeleteIcon
            sx={{ color: "red" }}
            className="cursor-pointer"
            id="btnRows"
            onClick={() => {
              dispatch(setProjectID(props.project_id));
              toast(<DeleteInitPopup />, {
                style: {
                  // fontSize: "20px",
                  // padding: "20px",
                  // borderRadius: "10px",
                  // width: "300px",
                },
              });
            }}
          />
          </div>
        </td>
      </tr>
    </>
  );
};

function downloadIniative(id) {
  let obj2 = [];
  let row;
  let test = { id: Number(id) };
  axios
    .post(GET_INITIATIVE_DATA_URL, test)
    .then((res) => {
      let obj = res.data;
      // console.log(obj)
      const filename = "ajs jas";
      const exportType = "csv";
      const project = obj;

      const updatedProjects = [
        {
          "PROJECT NAME": project[0].project_title,
          ADDRESS: project[0].street_address,
          COUNTRY: project[0].country_name,
          LATITUDE: project[0].latitude,
          LONGITUDE: project[0].longitude,
          CAMPUS: project[0].campus_name,
          "START DATE": project[0].start_date,
          "END DATE": project[0].end_date,
          STATUS: project[0].project_status,
          FUNDERS: project[0].funders_title,
          "EST. PROJECT VALUE": project[0].est_project_value,
          "PROJECT CONTACT INFO": project[0].project_contact_info,
          "PROJECT THEME": project[0].project_theme,
          SDGs: project[0].sdg_id,
          DESCRIPTION: project[0].project_description,
          "PROJECT LEAD NAME": project[0].project_lead,
          "PROJECT LEAD EMAIL": project[0].project_lead_email,
          "TEAM MEMBERS": project[0].team_members,
          URL: project[0].project_website,
          "FUNDER URL": project[0].funder_website,
        },
      ];
      const sdgs = updatedProjects[0].SDGs;
      const cleaned = sdgs.replace(/["\[\]]/g, "");
      updatedProjects[0].SDGs = cleaned;
      console.log(updatedProjects);
      //   const sdgIdStr = updatedProjects.SDGs;
      //   const sdgIdArr = JSON.parse(sdgIdStr);
      //   const sdgIdCsv = sdgIdArr.join(",");
      //   updatedProjects.SDGs=sdgIdCsv
      //   console.log(sdgIdArr)

      // console.log(data)
      // console.log(data[0].sdg_id)
      // delete data[0].id
      // delete data[0].is_available
      // delete data[0].image_id
      // delete data[0].resource_id
      // data[0].project_title =
      // data[0].sdg_id = sdgIdCsv
      // console.log(data.sdg_id)
      exportFromJSON({ data: updatedProjects, filename, exportType });
    })
    .catch((err) => toast.error(err));
  console.log(obj2);
}

export default RowsUpload;

import Footer from "../Components/Footer";
import Navbar from "../Components/navbar";
import Secbar2 from "../Components/secbar2";
import axios from "axios";
import PopupRows from "../Components/managePopup";
import PopupPass from "../Components/PopUpPass";
import { useSelector, useDispatch } from "react-redux";
import { CHANGE_PASSWORD_URL,SET_USER_PASSWORD } from "../apiEndpoints";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {toast} from 'react-toastify'
import ConfirmPopup4 from "../Components/confirmPopup4";
import { useNavigate } from "react-router-dom";

const ChangePassword = (props) => {
  let navigate = useNavigate();
  const handleBackToLoginClick = () => {
    // Perform navigation to the login page
    navigate("/login");
  };
  let AuthTokens = useSelector((state) => state.AuthTokens);
  let email = useSelector((state) => state.User).email;
  let role = useSelector(state=>state.User).roles_title
  function updatePassword() {
    const oldPassword = document.getElementById("previousPassword").value;
    const newPassword = document.getElementById("newPassword").value;
    const confirmPassword = document.getElementById("confirmPassword").value;

    //for toastify
    if (oldPassword == "" && newPassword == "" && confirmPassword == "") {
      //If password empty
      toast.error("Please ensure all password fields are filled out.");
    }
    else if (oldPassword == "") {
      //If password empty
      toast.error("Old password empty.");
    }

    else if (newPassword == "") {
      //If previous password empty
      toast.error("New password empty.");
    }
    else if (confirmPassword == "") {
      toast.error("Confirm password empty.");
    }
    if (newPassword != confirmPassword) {
      //If new password and confirm password dont match
      toast.error("Passwords not matching.");
    } else {
      let info = {
        email: email,
        oldpassword: oldPassword,
        newpass: newPassword,
        confirmpass: confirmPassword,

        
      };
      const changePassword = async () => {
        // console.log(password);
        let response = await fetch(SET_USER_PASSWORD, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(AuthTokens.access),
          },
          body: JSON.stringify(info),
        });
        let data = await response.json();
        if (response.status === 200) {
          // console.log(data)
          // document.getElementById('helper5').style.display = 'flex';
          toast(<ConfirmPopup4 onBackToLoginClick={handleBackToLoginClick} />, {
            // Toast options here
          });
        } else {
          // console.log("bugging");
        }
      };
      changePassword();
    }
  }
  return (
    <div>
      <Navbar />
      <Secbar2 />
      <div className="lg:px-16 lg:py-4 px-4 py-4">
        <div className="lg:w-1/2">
          <div className="pb-4">
            <p className="text-xl font-bold">Change Password</p>
            <p className="text-lg text-text-dark-grey">
              To change your password confirm your previous password, your
              password must be atleast 8 characters containing a number and one
              special character
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <label className="">Previous Password</label>
              <input
                className="rounded-sm border border-gray-300 bg-white p-2 "
                type="password"
                name="ppwd"
                id="previousPassword"
              />
            </div>
            <div className="flex flex-col">
              <label className="label-p">New Password</label>
              <input
                className="rounded-sm border border-gray-300 bg-white p-2 "
                type="password"
                name="npwd"
                id="newPassword"
              />
            </div>
            <div className="flex flex-col">
              <label className="label-p">Confirm Password</label>
              <input
                className="rounded-sm border border-gray-300 bg-white p-2 "
                type="password"
                name="cpwd"
                id="confirmPassword"
              />
            </div>
          </div>
          <div className="pt-4 flex flex-col lg:flex-row items-center">
            <Button
              sx={{ backgroundColor: "#AA1111", "&:hover": {
                backgroundColor: "#CB1818"} }}
              variant="contained"
              disableElevation
              className="w-full lg:w-auto"
              onClick={() => updatePassword()}
            >
              <span style={{ textTransform: "none" }}>Change Password</span>
            </Button>

            <Link
              href="/"
              className="pl-0 lg:pl-2 pt-4 lg:pt-0"
              sx={{ color: "black" }}
              underline="none"
            >
              {"Cancel"}
            </Link>
          </div>
        </div>
      </div>
      <br></br>
      <PopupPass />
      <Footer />
    </div>
  );
};

export default ChangePassword;

const API_ROOT = 'https://climatechangekp.com/api';
// const API_ROOT = 'http://0.0.0.0:8000/api'
// const API_ROOT = 'http://10.0.40.144:8000/api'


export const BASE_API_URL = `${API_ROOT}`; //https://climatechangekp.com/api/
export const HEARTBEAT = `${API_ROOT}/heartbeat/`; //https://climatechangekp.com/api/
export const DUMMY_SESSION = `${API_ROOT}/sessionTest`;
export const TOKEN_URL = `${API_ROOT}/token/`; //https://climatechangekp.com/api/token/
export const GET_USERS_URL = `${API_ROOT}/getUsers/`; //https://climatechangekp.com/api/getUsers/
export const FILTER_USERS_URL = `${API_ROOT}/filterUserNames/`; //https://climatechangekp.com/api/filterUserNames/
export const ADD_USER_URL = `${API_ROOT}/addUser/`; //https://climatechangekp.com/api/addUser/
export const EDIT_USER_URL = `${API_ROOT}/edit/`; //https://climatechangekp.com/api/edit/
export const FORGOT_PASSWORD_URL = `${API_ROOT}/forgotPassword/`; //https://climatechangekp.com/api/forgotPassword/
export const GET_PROJECTS_URL = `${API_ROOT}/projects/`; //https://climatechangekp.com/api/projects/
export const DELETE_USER_URL = `${API_ROOT}/deleteUser/`; //https://climatechangekp.com/api/deleteUser/
export const GET_FAQ_URL = `${API_ROOT}/faq/`; //https://climatechangekp.com/api/faq/
export const CHANGE_PASSWORD_URL = `${API_ROOT}/changePassword/`; //https://climatechangekp.com/api/changePassword/
export const UPDATE_USER_URL = `${API_ROOT}/updateUser/`; //https://climatechangekp.com/api/updateUser/
export const ADD_PROJECT_URL = `${API_ROOT}/addProject/`; //https://climatechangekp.com/api/addProject/
export const DELETE_PROJECT_URL = `${API_ROOT}/deleteProject/`; //https://climatechangekp.com/api/deleteProject/
export const GET_INITIATIVE_DATA_URL = `${API_ROOT}/getInitData/`; //https://climatechangekp.com/api/getInitData/
export const GET_DOCUMENTS_URL = `${API_ROOT}/documents/`; //https://climatechangekp.com/api/documents/
export const FILTER_PROJECTS_NAMES_URL = `${API_ROOT}/filterProjectNames/`; //https://climatechangekp.com/api/filterProjectNames/
export const FILTER_PROJECTS_URL = `${API_ROOT}/filterProjects/`; //https://climatechangekp.com/api/filterProjects/
export const UPLOAD_RESOURCES_URL = `${API_ROOT}/uploadResources/`; //https://climatechangekp.com/api/uploadResources/
export const UPLOAD_IMAGE_URL = `${API_ROOT}/uploadImage/`; //https://climatechangekp.com/api/uploadImage/
export const DYNAMIC_FILTER_URL = `${API_ROOT}/dynamicFilter/`; //'https://climatechangekp.com/api/dynamicFilter/
export const GET_SDGS_URL = `${API_ROOT}/sdg/`; //https://climatechangekp.com/api/sdg/
export const DELETE_PERMANENTLY_PROJECT = `${API_ROOT}/delete_Perm_Project/`; //https://climatechangekp.com/api/delete_Perm_Project/
export const UPLOAD_URL = `${API_ROOT}/upload/`; //https://climatechangekp.com/api/upload/
export const TOKEN_REFRESH_URL = `${API_ROOT}/tes/`; //https://climatechangekp.com/api/token/refresh
export const UPDATE_USER_PASSWORD = `${API_ROOT}/updatePassword/`;
export const SET_USER_PASSWORD = `${API_ROOT}/setPassword/`;
export const DELETE_PROJECT_RESOURCE = `${API_ROOT}/deleteResource/`;




import { useState } from "react";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLoggedIn, setAuthTokens, setIsLoggedIn } from "./reducers";
import { setUser } from "./reducers";
import jwt_decode from "jwt-decode";
import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import { TOKEN_URL } from "../apiEndpoints";
import { useEffect } from "react";
import TokenExpirationPopup from "../Components/tokenPopup";

const PlainBoxBorder = ({ text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
          //   width: 128,
          //   height: 128,
        },
      }}
    >
      <Paper elevation={3}>{text}</Paper>
    </Box>
  );
};

const Login = () => {

  //localStorage.getItem('authTokens')
  let navigate = useNavigate();
  const [error, setError] = useState("");
  let dispatch = useDispatch();
  const login = async (e) => {
    e.preventDefault();
    let response = await fetch(TOKEN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: document.querySelector("#email1").value,
        password: document.querySelector("#pword1").value,
      }),
    });
    let data = await response.json();
    if (response.status === 200) {
      const accessToken = jwt_decode(data.access);
      dispatch(setAuthTokens(data));
      dispatch(setUser(accessToken));
      localStorage.setItem("authTokens", JSON.stringify(data));
      localStorage.setItem("tokenTime", Date.now().toString());

      // Set up a timer for automatic logout
      const currentTime = Date.now() / 1000; // Convert to seconds
      const tokenExpirationTime = accessToken.exp;
      const timeUntilExpiration = tokenExpirationTime - currentTime;

      if (timeUntilExpiration > 0) {
        const logoutTimer = setTimeout(() => {
          dispatch(setUser(null))
          dispatch(setIsLoggedIn(false))
          localStorage.removeItem("authTokens")
          dispatch(setAuthTokens(null))
          navigate("/login", { replace: true });
          toast(<TokenExpirationPopup/>)
        }, timeUntilExpiration * 1000); // Convert to milliseconds
        localStorage.setItem("logoutTimer", logoutTimer.toString());
      }

      dispatch(setIsLoggedIn(true));
      navigate("/", { replace: true });
    }
    if (response.status == 401) {
      toast.error("Incorrect Password");
    } else if (response.status == 400) {
      let errorName = (Object.keys(data))
      console.log(errorName)
      if (errorName.length > 0) {
        // Use the '&' separator for multiple field names
        const joinedFieldNames = errorName.join(' and ');
        toast.error(`Please ensure that ${joinedFieldNames} ${errorName.length > 1 ? 'are' : 'is'} filled in.`);
      } 

    }
    console.log(data);
  };

  useEffect(() => {
    // Check if a logout timer is set
    const logoutTimer = localStorage.getItem("logoutTimer");
    if (logoutTimer) {
      clearTimeout(parseInt(logoutTimer, 10));
    }
  }, []);
  const handleKeyDown = (event) => {
    console.log(event); // Check what's inside the event object
    if (event.key === 'Enter') {
      login();
    }
  };

  const [inputValue, setInputValue] = useState("");


  return (
    <div>
      <div className="Login">
        <div className="Login-top">
          <h1 className="text-lg font-semibold">CCKP PORTAL</h1>
        </div>
        <p className="login-error-message">{error}</p>
        <div className="w-max mx-auto mb-6">
          <Box
            sx={{
              //   display: "flex",
              display: "inline-block",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                //   width: 128,
                //   height: 128,
              },
            }}
          >
            <Paper elevation={3}>
              <div className="login-center  mx-auto ">
                <div className="login_form">
                  <h1>Log In</h1>
                  <div className="email-div">
                    <label className="font-semibold">Email</label>
                    <input
                      className="w-full"
                      type={"email"}
                      placeholder={"johndoe@gmail.com"}
                      id="email1"
                    />
                  </div>
                  <div className="pword-div">
                    <label className="font-semibold">Password</label>
                    <input
                      type={"password"}
                      placeholder={"password"}
                      id="pword1"
                             />
                  </div>
                  <div className="remember-me-div justify-end">
                    <div>
                      <input
                      
                        type="checkbox"
                        id="checkbox-testing"
                        value="lsRememberMe"
                      ></input>
                    </div>
                    <label>Remember me</label>
                  </div>

                  <button className="log-in-btn" onClick={login} >
                    Log In
                  </button>
                  <button className="forgot-btn">
                    <Link to="/forgot_password">Forgot password?</Link>
                  </button>
                </div>
              </div>
            </Paper>
          </Box>
          <div className="text-center">
            <Link to="/" className="">
              Go back home
            </Link>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Login;

import * as React from "react";
import Navbar from "../Components/navbar";
import Footer from "./Footer";
import Secbar2 from "./secbar2";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TableRows from "../pages/tableRow";
import { BASE_API_URL, GET_DOCUMENTS_URL, GET_SDGS_URL } from "../apiEndpoints";
import Button from "@mui/material/Button";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { sdgNames, setSDGNames } from "../pages/reducers";
import PublicIcon from "@mui/icons-material/Public";
import SchoolIcon from "@mui/icons-material/School";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import withTokenExpiration from "./withTokenExpiration";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";

const InventoryFullPage = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [Resources, setResources] = useState([]);
  const currentInit = useSelector((state) => state.currentInit);
  const isAdmin = useSelector((state) => state.User?.roles_title);
  const sdg = useSelector((state) => state.currentInit.sdg_id);
  const [sdgnames, setsdgnames] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let response = await fetch(GET_DOCUMENTS_URL, {
        method: "GET",
      });
      let data = await response.json();
      if (response.status === 200) {
        setResources(data);
      } else {
        console.log("bugged oout");
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getSDG = async () => {
      try {
        let response = await fetch(GET_SDGS_URL, {
          method: "GET",
        });
        if (response.status === 200) {
          let data = await response.json();

          // Parse the SDG IDs from Redux state as integers
          const currentSDGIds = JSON.parse(sdg).map((id) => parseInt(id));

          // Initialize an array to store SDG names
          const currentSDGNames = [];

          // Find the SDG names for each SDG ID
          currentSDGIds.forEach((id) => {
            const sdg = Object.keys(data).find((key) => {
              return data[key].sdg_id === id;
            });
            if (sdg) {
              currentSDGNames.push(data[sdg].sdg_name);
              setsdgnames(currentSDGNames);
            }
          });

          // console.log(currentSDGNames);
        } else {
          console.log("Request to fetch SDGs failed");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    getSDG();
  }, [sdg]);

  // console.log(sdgnames)
  return (
    <div>
      {isAdmin ? (
        <div>
          <Navbar />
          <p></p> <Secbar2 />
        </div>
      ) : (
        <Navbar />
      )}

      <div>
        <div className=" flex lg:px-16 lg:py-4 px-4 py-4">
          <div className="w-3/4 pr-4">
            <p className="text-xl font-bold">{currentInit.project_title}</p>
            <p className="text-base pt-2">
              <div>
                {currentInit.project_status === "ONGOING" || currentInit.project_status === "Ongoing" || currentInit.project_status === "ongoing"? (
                  <p className="rounded-full bg-ongoing-project-yellow inline-block px-2 py-1 text-white font-semibold">
                    {currentInit.project_status.toUpperCase()}
                  </p>
                ) : currentInit.project_status === "COMPLETE" || currentInit.project_status === "Complete" || currentInit.project_status === "complete"? (
                  <p className="rounded-full bg-green-500 inline-block px-2 py-1 text-white font-semibold">
                    {currentInit.project_status.toUpperCase()}
                  </p>
                ) : currentInit.project_status === "PAUSED" ? (
                  <p className="rounded-full bg-ongoing-project-yellow inline-block px-2 py-1 text-white font-semibold">
                    {currentInit.project_status.toUpperCase()}
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
            </p>
            <br></br>
            <div className="">
              <div className="flex flex-wrap gap-2">
              <div className="flex flex-wrap gap-2">
                <div>
                  <Chip
                    sx={{
                      height: "auto",
                      borderRadius: "4px", // Set the desired border radius value
                      "& .MuiChip-label": {
                         display: "inline-block",
          whiteSpace: "nowrap",

                        padding: "4px 12px",
                      },
                    }}
                    label={
                      <div className="flex items-center">
                        <CalendarMonthIcon />
                        <div className="pl-1">
                          <p className="text-base font-bold text-dark-grey">
                            Since
                          </p>
                          <p className="text-base">{currentInit.start_date}</p>
                        </div>
                      </div>
                    }
                  />
                </div>
                <div>
                  {" "}
                  <Chip
                    sx={{
                      height: "auto",
                      borderRadius: "4px", // Set the desired border radius value
                      "& .MuiChip-label": {
                        display: "inline-block",
                        whiteSpace: "nowrap", // Prevent text from wrapping

                        padding: "4px 12px",
                      },
                    }}
                    label={
                      <div className="flex items-center">
                        <CalendarMonthIcon />
                        <div className="pl-1">
                          <p className="text-base font-bold text-dark-grey">
                            End Date
                          </p>
                          <p className="text-base">{currentInit.end_date}</p>
                        </div>
                      </div>
                    }
                  />
                </div>
                <div>
                  {" "}
                  <Chip
                    sx={{
                      height: "auto",
                      borderRadius: "4px", // Set the desired border radius value
                      "& .MuiChip-label": {
                        display: "inline-block",
                        whiteSpace: "nowrap", // Prevent text from wrapping

                        padding: "4px 12px",
                      },
                    }}
                    label={
                      <div className="flex items-center">
                        <PublicIcon />
                        <div className="pl-1">
                          <p className="text-base font-bold text-dark-grey ">
                            {" "}
                            Country
                          </p>
                          <p className="text-base">
                            {" "}
                            {currentInit.country_name}{" "}
                          </p>
                        </div>{" "}
                      </div>
                    }
                  />
                </div>
                <div>
                  {" "}
                  <Chip
                    sx={{
                      height: "auto",
                      borderRadius: "4px", // Set the desired border radius value
                      "& .MuiChip-label": {
                        display: "inline-block",
                        whiteSpace: "nowrap", // Prevent text from wrapping

                        padding: "4px 12px",
                      },
                    }}
                    label={
                      <div className="flex items-center">
                        <AttachMoneyIcon />
                        <div className="pl-1">
                          <p className="text-base font-bold text-dark-grey ">
                            Project Value
                          </p>
                          <p className="text-base">
                            {currentInit.est_project_value}{" "}
                          </p>
                        </div>{" "}
                      </div>
                    }
                  />
                </div>
                <div>
                  {" "}
                  <Chip
                    sx={{
                      height: "auto",
                      borderRadius: "4px", // Set the desired border radius value
                      "& .MuiChip-label": {
                        display: "inline-block",
                        whiteSpace: "nowrap", // Prevent text from wrapping

                        padding: "4px 12px",
                      },
                    }}
                    label={
                      <div className="flex items-center">
                        <SchoolIcon />
                        <div className="pl-1">
                          <p className="text-base font-bold text-dark-grey ">
                            {" "}
                            Campus
                          </p>
                          <p className="text-base">
                            {" "}
                            {currentInit.campus_name}{" "}
                          </p>
                        </div>{" "}
                      </div>
                    }
                  />
                  
                </div>
                <div>
                <Chip
                  sx={{
                    height: "auto",
                    borderRadius: "4px", // Set the desired border radius value
                    "& .MuiChip-label": {
                      display: "inline-block",
                      whiteSpace: "nowrap", // Prevent text from wrapping

                      padding: "4px 12px",
                    },
                  }}
                  label={
                    <div className="flex items-center">
                      <AutoAwesomeMosaicIcon />
                      <div className="pl-1">
                        <p className="text-base font-bold text-dark-grey ">
                     
                          Theme
                        </p>
                        <p className="text-base">
             
                          {currentInit.project_theme}
                        </p>
                      </div>
                    </div>
                  }
                />
                </div>
              </div>
              </div>
              <div > 
                   {/* <div className="text-base w-1/5 w-full justify-center lg:justify-end  flex overflow-x-auto flex-row flex-wrap gap-2"> */}
            <br></br>
            <p className="font-bold text-base block lg:hidden">SDGs</p>
            <div className="flex lg:flex sm:block gap-2">
            
   
                {JSON.parse(currentInit.sdg_id).map((sdg, index) => {
                  const sdgName = sdgnames[index] || ""; // Get the corresponding SDG name if available
                  const sdgPair = `${sdg} - ${sdgName}`;
                  const baseUrl =
                    "https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-";

                  // Use String formatting to create the URL with the SDG number
                  const imageUrl = `${baseUrl}${sdg
                    .toString().trim()
                    .padStart(2, "0")}.jpg`;

                  return (
                    <div className=" flex-col">
                      
                    <img
                      className="flex hidden lg:block w-16 h-16 lg:w-24 lg:h-24 rounded-sm"
                      src={imageUrl}
                      alt={`SDG ${sdg}`}
                    />
                    <div>
                      
                      </div>
                    <div className=" lg:hidden">
                     {index === 0 ? `${sdgPair} \n` : `${sdgPair}`}
                     
                    </div>
                    </div>
                  );
                  //   });
                })}
                {/* {console.log(currentInit.sdg_id.sdg_name)} */}
              </div>
              </div>
            </div>
            {/* <div className="flex flex-col md:flex-row"> */}
            <div className="flex">
              {/* <div className="flex w-4/5 flex-1 overflow-x-auto flex-wrap gap-2"> */}
             
              {/* <br></br> */}
             
              
           
            </div>
            <p className="font-bold text-base pt-2">Address</p>
            <p className="text-base">{currentInit.street_address} </p>
            <p className="text-base font-bold pt-2">Funder </p>
            <p className="text-base">{currentInit.funders_title}</p>
            <p className="text-base font-bold pt-2">Project Description</p>
            <p className="text-base text-text-dark-grey">
              {currentInit.project_description}
            </p>
          </div>
{/* Two */}
          <div className="w-1/4 w-64 mx-auto rounded-sm p-4 bg-white shadow-md">
            <div className="border border-gray-300 rounded bg-white p-4 ">
              {currentInit.image_id != null ? (
                <img
                  className=""
                  src={
                    BASE_API_URL +
                    `/media/project_${currentInit.id}/${currentInit.id}.jpg`
                  }
                ></img>
              ) : (
                <img
                  className=""
                  src={require("../Images/default_image.jpeg")}
                ></img>
              )}
            </div>
            <div className="pt-2">
            <p className="text-base font-bold pt-2">Project Lead</p>
              <p className="text-base">
                {currentInit.project_lead}
              </p>
    <p className="text-base font-bold pt-2">Team Members</p>
              <p className="text-base">
                {currentInit.team_members}
              </p>
              <p className="text-base pt-2">
                <PhoneEnabledIcon />
                {currentInit.project_contact_info}
              </p>
              <p className="text-base">
                <EmailIcon />
                {currentInit.project_lead_email}{" "}
              </p>

              <div className="flex flex-col gap-2 pt-2">
                {currentInit.funder_website !== "nan" ? (
                  <Button href={currentInit.funder_website} variant="contained">
                    View Website
                  </Button>
                ) : (
                  <span></span>
                )}
                {/* {console.log(currentInit.funders_url)} */}
                {/* {console.log(currentInit)} */}
                {currentInit.funder_website !== "nan" ? (
                  <Button href={currentInit.funder_website} variant="outlined">
                    View Funder's Website
                  </Button>
                ) : (
                  <span></span>
                )}
                <Button href={"/inventory"} variant="outlined">
                  View More Initiatives
                </Button>
              </div>
            </div>
          </div>


        </div>
        <div className="px-16 py-4 bg-custom-gray">
          <p className="text-lg font-bold">Resources</p>
          <p className="text-base">
            Below are resources that relate to the Initiative.{" "}
          </p>
          <br></br>

          <div>
            <table className="w-full">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="" className="px-4 py-2">
                        Resource Name
                      </TableCell>
                      <TableCell align="" className="py-2">
                        Date Uploaded
                      </TableCell>
                      <TableCell align="" className="py-2">
                        Options
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Resources.map((item) => {
                      if (currentInit.id === item.project_id) {
                        let test = item.upload_date.split("T");
                        item.upload_date = test[0];
                        return (
                          <TableRows
                            key={item.id}
                            name={item.name}
                            path={item.document}
                            dates={item.upload_date}
                            showDeleteButton={false}
                          />
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withTokenExpiration(InventoryFullPage);

import React, { useState, useMemo, useEffect } from "react";
import Pagination from "./Pagination";
import axios from "axios";
import { convertToArrayOfObjects2 } from "../Components/datamangers";
import RowsUpload from "../Components/rowUpload";
import { useSelector, useDispatch } from "react-redux";
import { setNewInits } from "../pages/reducers";
import {
  FILTER_PROJECTS_NAMES_URL,
  FILTER_PROJECTS_URL,
  GET_PROJECTS_URL,
} from "../apiEndpoints";
//import {getInitiatives} from '../pages/posted_initiatives'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"
import TokenExpirationPopup from "../Components/tokenPopup";
let PageSize = 5;

export default function TestUpload(props) {
  const navigate = useNavigate()
  const [usersArray, setUserArray] = useState([]);
  const initsArray = useSelector((state) => state.initsArray);
  const [currentPage, setCurrentPage] = useState(0);
  const AuthTokens = useSelector((state) => state.AuthTokens);
  const changes = useSelector((state) => state.view_inits_changes);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedComplete, setIsCheckedComplete] = useState(false);
  const dispatch = useDispatch();
  const handleBackToLogin = () => {
    // Perform navigation to the login page
    navigate("/login");
  };
  async function getNames() {
    let searchName = document.getElementById("getSearch").value;
    console.log(searchName);
    let test = { init_name: searchName };
    if (searchName == "") {
      const getData = async () => {
        let arr = [];
        let response = await fetch(GET_PROJECTS_URL, {
          method: "GET",
        });
        let data = await response.json();
        if (response.status === 200) {
          setUserArray(data);
          dispatch(setNewInits(data));
        } else {
          console.log("bugged oout");
        }
      };
      getData();
    } else {
      const getData = async () => {
        let response = await fetch(FILTER_PROJECTS_NAMES_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'Authorization':'Bearer '+ String(AuthTokens.access)
          },
          body: JSON.stringify(test),
        });
        let data = await response.json();
        if (response.status === 200) {
          setUserArray(data);
          dispatch(setNewInits(data));
          console.log(data);
        } else {
          console.log("bugged oout");
        }
      };
      getData();
    }
  }
  async function getInitiatives() {
    let status;
    let check = document.getElementById("checkbox-complete");
    let check2 = document.getElementById("checkbox-ongoing");
    if (check.checked && check2.checked) {
      setIsChecked(true);
      const getData = async () => {
        let response = await fetch(GET_PROJECTS_URL, {
          method: "GET",
        });
        let data = await response.json();
        if (response.status === 200) {
          setUserArray(data);
          dispatch(setNewInits(data));
          console.log(data);
        } else {
          console.log("bugged oout");
        }
      };
      getData();
    } else {
      if (check.checked) {
        status = "COMPLETE";
      }
      if (check2.checked) {
        status = "ONGOING";
      }
      let test = { status: status };
      if (!check.checked && !check2.checked) {
        const getData = async () => {
          let response = await fetch(GET_PROJECTS_URL, {
            method: "GET",
          });
          let data = await response.json();
          if (response.status === 200) {
            setUserArray(data);
            dispatch(setNewInits(data));
          } else {
            console.log("bugged oout");
          }
        };
        getData();
      } else {
        const getData = async () => {
          let response = await fetch(FILTER_PROJECTS_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // 'Authorization':'Bearer '+ String(AuthTokens.access)
            },
            body: JSON.stringify(test),
          });
          let data = await response.json();
          if (response.status === 200) {
            console.log(data);
            setUserArray(data);
            dispatch(setNewInits(data));
          } else {
            console.log("bugged oout");
          }
        };
        getData();
      }
    }
  }

  useEffect(() => {
    const getData = async () => {
      let arr = [];
      let response = await fetch(GET_PROJECTS_URL, {
        method: "GET",
      });
      let data = await response.json();
      if (response.status === 200) {
        setUserArray(data);
        dispatch(setNewInits(data));
        // console.log(data);
      } else if(response.status === 401){
        toast(<TokenExpirationPopup onBackToLogin={handleBackToLogin}/>)
        navigate('/login',{replace:true})
      }else {
        console.log("bugged oout");
      }
    };
    setCurrentPage(1);
    getData();
  }, [props.id, changes]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return initsArray.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, initsArray]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checked state
  };

  // Step 3: Handle Cancel
  const handleCancelClick = () => {
    setIsChecked(false); // Uncheck the checkbox
  };

  const handleCheckboxChangeComplete = () => {
    setIsCheckedComplete(!isCheckedComplete); // Toggle the checked state
  };

  // Step 3: Handle Cancel
  const handleCancelClickComplete = () => {
    setIsCheckedComplete(false); // Uncheck the checkbox
  };

  if (usersArray) {
    return (
      <>
        <div>
          <br></br>
          {/* border border-gray-600 rounded-md */}
          <div className="flex flex-row"></div>
        </div>
        <div className="flex">
          <div className="border rounded-sm border-grey-500 mr-2 py-2 px-2 ">
            <input
            className="w-full"
              style={{  }}
              type="search"
              placeholder="Search"
              id="getSearch"
              onInput={getNames}
            ></input>
          </div>
          <button onClick={myFunction4} className="filter">
            Filters{" "}
          </button>
          <div id="myDropdown4" class="dropdown-content-posted">
            <h4 className="p-2">Status</h4>
           <div className="p-2">
           <div className="flex gap-2">
            <input 
              className="ongoing"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              id="checkbox-complete"
            />


            <h5 className="name-tag">COMPLETE</h5></div>

<div className="flex gap-2">
            <input
              className="completed"
              type="checkbox"
              checked={isCheckedComplete}
              onChange={handleCheckboxChangeComplete}
              id="checkbox-ongoing"
            />
            <h5 className="final-tag">ONGOING</h5> </div></div>
            <hr className="posted-hr"></hr>
            <div className="searchtext">
              <button
                className="postedcancel"
                onClick={() => {
                  handleCancelClick();
                  handleCancelClickComplete();
                }}
              >
                Cancel
              </button>
              <button onClick={getInitiatives} className="postedapply">
                Apply
              </button>
            </div>
          </div>
        </div>
        <div className="search-container">
          <div className="test"></div>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className="py-4 pl-4">
                  Ref #
                </TableCell>
                <TableCell align="center" className="py-2">
                  Initiative Name
                </TableCell>
                <TableCell align="center" className="py-2">
                  Status
                </TableCell>
                <TableCell align="center" className="py-2">
                  Options
                </TableCell>
                <TableCell align="center" className="py-2">
                  {" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentTableData.map(function (user, index) {

                return (
                  <RowsUpload
                    data={user}
                    project_id={user.id}
                    project_title={user.project_title}
                    status={user.project_status}
                    color={user.project_status}
                    available={user.is_available}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br></br>
        <div className="pagIstyles">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={usersArray.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </>
    );
  } else {
    return (
      <div>
        <h1>LOADING...</h1>
      </div>
    );
  }
}
function myFunction4() {
  document.getElementById("myDropdown4").classList.toggle("show");
}

// Close the dropdown menu if the user clicks outside of it
window.onclick = function (event) {
  if (!event.target.matches(".filter")) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
  }
};

// useAuth.js

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthTokens, setUser, setIsLoggedIn } from './pages/reducers';
import jwt_decode from 'jwt-decode';
import { HEARTBEAT,DUMMY_SESSION } from "./apiEndpoints";

const useAuth = () => {
  const dispatch = useDispatch();
  const [logoutTimer, setLogoutTimer] = useState(null);
  const authTokens = localStorage.getItem('authTokens')
  
  const handleLogout = () => {
    dispatch(setUser(null));
    dispatch(setIsLoggedIn(false));
    dispatch(setAuthTokens(null));
    localStorage.removeItem('authTokens');
  };

  
  const setLogoutTimeout = (expirationTime) => {
    const timeUntilExpiration = expirationTime - Date.now() / 1000;
    console.log('Setting logout timer with timeUntilExpiration:', timeUntilExpiration);
    if (timeUntilExpiration > 0) {
      const timer = setTimeout(() => {
        console.log('Auto-logout timer expired. Logging out...');
        handleLogout();
      }, timeUntilExpiration * 1000);
      setLogoutTimer(timer);
      localStorage.setItem('logoutTimer', timer.toString());
    }
  };
const handleExpiredSession = () => {
    // Clear user data and authentication tokens
    dispatch(setUser(null));
    dispatch(setIsLoggedIn(false));
    dispatch(setAuthTokens(null));

    // Clear items from localStorage
    localStorage.removeItem('authTokens');
    localStorage.removeItem('logoutTimer');

    // Additional cleanup or actions if needed

    // For example, you might redirect to the login page
    // history.push('/login');
  };

  const clearLogoutTimeout = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
      localStorage.removeItem('logoutTimer');
    }
  };

  const checkSessionStatus = async () => {
    try {
      const response = await fetch(HEARTBEAT, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Include your authentication token if needed
          Authorization: "Bearer " + String(authTokens.access),
        },
      });

      if (response.status === 401) {
        // Session has expired
        handleLogout();
      } else {
        // Session is still valid, update the logout timer if needed
        const data = await response.json();
        if (data && data.accessToken) {
          const accessToken = jwt_decode(data.accessToken);
          setLogoutTimeout(accessToken.exp);
        }
      }
    } catch (error) {
      console.error('Error checking session status:', error);
    }
  };

  useEffect(() => {
    const storedTokens = localStorage.getItem('authTokens');
    if (storedTokens) {
      const parsedTokens = JSON.parse(storedTokens);
      const accessToken = jwt_decode(parsedTokens.access);

      // Set authentication tokens and user data
      

      // Set up the logout timer
      setLogoutTimeout(accessToken.exp);
    }
    const dummyChecker = async (e) => {
      let response = await fetch(DUMMY_SESSION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization':'Bearer '+ String(authTokens.access)
        },
        
      });
      let data = await response.json();
      if (response.status !== 200) {
        localStorage.removeItem('authTokens')
        dispatch(setUser(null));
        dispatch(setIsLoggedIn(false));
        dispatch(setAuthTokens(null));
      }
    }
    dummyChecker()
    // Set up the periodic polling
    const heartbeatInterval = setInterval(() => {
      checkSessionStatus();
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => {
      // Cleanup: Clear the interval and logout timer when the component unmounts
      clearInterval(heartbeatInterval);
      clearLogoutTimeout();

    };
  }, [dispatch]);

  return { handleLogout, setLogoutTimeout, clearLogoutTimeout };
};

export default useAuth;

import React from 'react';
import { useNavigate } from 'react-router-dom';
const MyAccount=()=>{
  return(
    <div>
    <button onClick={myFunction3} id = 'sign-in' className='sign-in-btn-admin' ><img className = 'usr_icon' src = {require('../Images/user.png')}/>My Account<img className = 'img_sec'src={require('../Images/Polygon9.png')} alt = ''/></button>
    <div id="myDropdown3" class="dropdown-content-Admin">
        <a href="/change">Change password</a>
        <a href='/'>Log Out</a>
    </div>
</div>
  )
}

const NavbarAdmin=()=>{
  
  const navigate = useNavigate()

  const handleHomeClick = () => {
    navigate('/', { replace: true });
  };
return (
<div className='homePage'>
            <header className='topBar'> 
                <div className='logo1'>
                    <a href = 'https://www.uwi.edu/gicsrd/' >
                        <img src={require('../Images/logo.png')} />
                    </a>
                </div>
                <div className = 'logo2'>
                    <img src = {require('../Images/logo2.png')}/>
                </div>
                <ul className='nav-bar'>
                <a onClick={handleHomeClick} id='home_nav' >Home</a>
                    <li className="cursor-pointer" id='about-project'>About the Project</li>
                    <li className="cursor-pointer" id='visit-us'> Contact Us </li>
                    <li>
                      <MyAccount/>
                    </li>
                </ul>
            </header>
            <div>
</div>
</div>
);

};
export default NavbarAdmin;

function myFunction3() {
    document.getElementById("myDropdown3").classList.toggle("showAdmin");
  }
  
  // Close the dropdown menu if the user clicks outside of it
  window.onclick = function(event) {
    if (!event.target.matches('.sign-in-btn')) {
      var dropdowns = document.getElementsByClassName("dropdown-content-Admin");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('showAdmin')) {
          openDropdown.classList.remove('showAdmin');
          
        }
      }
    }
    
  }
import React from 'react'
import Navbar from './navbar';
import FullPage from './rightBar'

const RightPage =()=>{

    return(
        <>
        <FullPage/>
        </>
    )
}
export default RightPage;
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setChanges } from "../pages/reducers";
import { toast } from "react-toastify";
import { DELETE_USER_URL } from "../apiEndpoints";

export default function DeletePopUp({ closeToast }) {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.Email);
  const AuthTokens = useSelector((state) => state.AuthTokens);

  const deleteUser = async () => {
    let info = { email: email };
    const response = await fetch(
      DELETE_USER_URL,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(AuthTokens.access),
        },
        body: JSON.stringify(info),
      }
    );

    if (response.status === 200) {
      dispatch(setChanges());
      closeToast(); // Close the toast when deletion is successful
    } else {
      console.log("failed");
    }
  };

  const handleYesClick = () => {
    deleteUser();
  };

  const handleNoClick = () => {
    closeToast(); // Close the toast if the user clicks "No"
  };

  return (
    <div>
      <div className="">
        <div id="">
          <div id="">
            <p className="popUpText">
              Are you sure you want to delete this user?
            </p>
            <p>Clicking YES will remove this user</p>
            <button className="alert_button1" onClick={handleYesClick}>
              Yes
            </button>
            <button className="alert_button2" onClick={handleNoClick}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}


import {BrowserRouter, Route, Routes} from 'react-router-dom'
import ChangePassword from './pages/change_password';
import ManageUser from './pages/manage_users';
import UploadInitiatives from './pages/upload_initiatives';
import { useNavigate } from 'react-router-dom';
import './style.css';
import AddUser from './pages/add_user';
import PostedInitiatives from './pages/posted_initiatives';
import Login from './pages/login';
import ManageUserTable from './Components/ManageUsersTable';
import RightPage from './Components/NewPage';
import {createStore} from 'redux'
import { Provider, useDispatch, useSelector } from 'react-redux';
import EditUser from './pages/edit_user';
import PrivateRoute from './utils/PrivateRoutes';
import { AuthTokens, setAuthTokens, setIsLoggedIn } from './pages/reducers';
import jwt_decode from 'jwt-decode'
import { setUser } from './pages/reducers';
import { Logout } from './utils/Auth';
import { useEffect,useState } from 'react';
import { UpdateToken } from './utils/Auth';
import Inventory from './pages/inventory';
import InitiativeInventory from './Components/initiative';
import Edit_Initiative from './pages/edit_Initiative';
import InventoryFullPage from './Components/inventoryfullpage';
import InventoryUpload from './Components/inventoryUpload';
import ForgotPassword from './pages/forgot_password';
import HomePage from './pages/HomePage';
import { ToastContainer, toast, TypeOptions } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
// import { useDispatch } from 'react-redux';
import TokenExpirationPopup from './Components/tokenPopup';
import logoutUser from './Components/logoutUser';
import InactivityChecker from './Components/logoutInactivity';
import useAuth from './useAuth';

function App() {
  let dispatch=useDispatch()
  let userCreds=localStorage.getItem('authTokens')
  const logged = useSelector(state=>state.isLoggedIn)
  const logTimer = localStorage.getItem('logoutTimer')

 // Retrieve user credentials from local storage

// console.log('userCreds:', userCreds);

// Check if user credentials exist in local storage
if (userCreds && logTimer > 0) {
  console.log('Credentials exist.');
  // If credentials exist, parse and dispatch actions to set tokens and user data
  const parsedUserCreds = JSON.parse(userCreds);
  console.log('Parsed userCreds:', parsedUserCreds);
  dispatch(setAuthTokens(parsedUserCreds)); // Set authentication tokens
  dispatch(setUser(jwt_decode(parsedUserCreds.access))); // Set user data
} else {
  console.log('Credentials do not exist.');
  console.log('User should not be logged in')
  // If credentials don't exist, dispatch actions to clear tokens and user data
  dispatch(setAuthTokens(null));
  dispatch(setUser(null));
  localStorage.removeItem('authTokens')
}


const { handleLogout, setLogoutTimeout, clearLogoutTimeout } = useAuth();

useEffect(() => {
  // Check if a logout timer is set
  const logoutTimer = localStorage.getItem('logoutTimer');
  console.log('Logout timer:', logoutTimer);
  if (logoutTimer) {
    clearLogoutTimeout();
  }
}, [clearLogoutTimeout]);

  
  

  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
              <Route path='/' element={<HomePage/>}/>
              <Route path='/login' element={<Login/>}/>
              <Route path='/change' element={<PrivateRoute><ChangePassword/></PrivateRoute>}/>
              <Route path='/manage' element={<PrivateRoute><ManageUser/></PrivateRoute>}/>
              <Route path='/upload' element={<PrivateRoute><UploadInitiatives/></PrivateRoute>}/>
              <Route path='/adduser' element={<PrivateRoute><AddUser/></PrivateRoute>}/>
              <Route path ='/posted' element={<PrivateRoute><PostedInitiatives/></PrivateRoute>}/>
              <Route path = '/right' element = {<RightPage/>}/>
              <Route path = '/Edit' element = {<PrivateRoute><EditUser/></PrivateRoute>}/>
              <Route path = '/fullpage' element={<InventoryFullPage/>}/>
              <Route path = '/inventory' element={<InitiativeInventory/>}/>
              <Route path = '/Edit_Initiative' element={<PrivateRoute><Edit_Initiative/></PrivateRoute>}/>
              <Route path = '/invenUpload' element={<InventoryUpload/>}/>
              <Route path = '/forgot_password' element={<ForgotPassword/>}/>
          </Routes>      
        </BrowserRouter>
        <ToastContainer position="top-right" newestOnTop />
        {/* {logged && <InactivityChecker />} Render InactivityChecker when logged in */}
      </div>
  );
}

export default App;

import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  useMap,
  ZoomControl,
} from "react-leaflet";
import L from "leaflet";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentPoint } from "./reducers";
import { rigthbarAnimation } from "../Components/rightBar";
import { groupBy } from "../Components/datamangers";
import { convertObjecttoArray } from "../Components/datamangers";
import { BASE_API_URL } from "../apiEndpoints";
import Divider from "@mui/material/Divider";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import {
  DarkButton,
  DarkButtonOnClick,
} from "../Components/MUI_Components/button";
import { sdgNames, setSDGNames } from "../pages/reducers";

const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});

const position2 = [18.476223, -77.89389];



function RenderCsvToArray() {
  const [sdgnames, setsdgnames] = useState([]);
  const currentPoint = useSelector((state) => state.currentPoint);
  let csvToArray = useSelector((state) => state.currentlyPlottedPoints);
  let AllSDGS = useSelector((state) => state.SDGs);

  let dupsarr = convertObjecttoArray(groupBy(csvToArray, "latitude"));

  
  const dispatch = useDispatch();
  const map = useMap();

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  // const filteredPoints = csvToArray.filter(exactpoints => exactpoints.is_available);
  const [activeSteps, setActiveSteps] = React.useState({});

  



  // function changeSteps(num) {
  //   maxSteps = num;
  //   if (num != 0) {
  //     //  setActiveStep(0)
  //   }
  // }


  const changeActiveStep =()=>{
    setActiveStep(0)
  }
  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };


  useEffect(() => {
    console.log("loaded");
  }, []);
  let initsName;

  
  {
    return dupsarr.map((point, index) => {
      let gr1;
      if (point.length > 1) {
        gr1 = true;
      } else {
        gr1 = false;
      }
      let gr2;
      let count = 0;
      if (count > 1) {
        gr2 = true;
      } else {
        gr2 = false;
        count++;
      }
      let i = 0;
      // console.log(point)

      const filteredPointsForMarker = point.filter((exactpoints) => exactpoints.is_available);
      let maxSteps = filteredPointsForMarker.length;
      // console.log(maxSteps)
      const markerKey = `marker_${index}`;

      const handleNext = (markerKey, maxSteps) => {
        setActiveSteps((prevActiveSteps) => ({
          ...prevActiveSteps,
          [markerKey]: ((prevActiveSteps[markerKey] || 0) + 1) % maxSteps,
        }));
      };
    

      const handleBack = (markerKey, maxSteps) => {
        setActiveSteps((prevActiveSteps) => ({
          ...prevActiveSteps,
          [markerKey]: (maxSteps + prevActiveSteps[markerKey] - 1) % maxSteps,
        }));
      };
      

      function changeSteps(points) {
        // setActiveStep(0)
        const filteredPoints = points.filter(exactpoints => exactpoints.is_available);
        maxSteps = filteredPoints.length;
      }

      return (
        
        <Marker
        key={markerKey}
          icon={icon}
          onChange={changeSteps(point)}
          position={[point[0].latitude, point[0].longitude]}
        >
          <Popup >
            <Box sx={{ maxWidth: "auto" }}>
              {/* {console.log(exactpoints.project_status)} */}

              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeSteps[markerKey] || 0}
                onChangeIndex={(step) => handleNext(markerKey, maxSteps)}
                enableMouseEvents
              >
                {filteredPointsForMarker.map((exactpoints, index) => {

                  return exactpoints.is_available ? (
                    
                  
                  <div key={exactpoints.label}>
                    {/* {console.log(point.length)} */}
                      
                      <div>
                        {/* <p className="pt-5 ">Title</p> */}
                        {/* <p className="pt-5 bg-red-500 text-center justify-center items-center pb-4">Title</p> */}
                        {exactpoints.project_status === "ONGOING" || exactpoints.project_status === "Ongoing" || exactpoints.project_status === "ongoing"? (
                          <p className="rounded-tl-lg rounded-tr-lg bg-ongoing-project-yellow p-2 text-white font-semibold text-center">
                            {exactpoints.project_status.toUpperCase()}
                          </p>
                        ) : exactpoints.project_status === "COMPLETE" || exactpoints.project_status === "Complete" || exactpoints.project_status === "complete"? (
                          <p className="rounded-tl-lg rounded-tr-lg bg-green-500 p-2 text-white font-semibold text-center">
                            {exactpoints.project_status.toUpperCase()}
                          </p>
                        ) : exactpoints.project_status === "PAUSED" ? (
                          <p className="rounded-tl-lg rounded-tr-lg bg-ongoing-project-yellow p-2 text-white font-semibold text-center">
                            {exactpoints.project_status}
                          </p>
                        ) : (
                          <p></p>
                        )}
                        <div className="h-[275px] overflow-x-auto"   style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
 >
                         {/* {exactpoints.projectStatus} */}
                         {exactpoints.image_id !== null ? (
                            <div className="flexing">
                              <img
                                alt="marker"
                                className="flexing"
                                style={{
                                  maxHeight: "200px",
                                  overflow: "hidden",
                                }}
                                src={
                                  BASE_API_URL +
                                  `/media/project_${exactpoints.id}/${exactpoints.id}.jpg`
                                }
                              ></img>
                            </div>
                          ) : (
                            <div className="flexing">
                              <img
                                alt="marker"
                                className="flexing"
                                style={{
                                  maxHeight: "200px",
                                  overflow: "hidden",
                                }}
                                src={require("../Images/default_image.jpeg")}
                              ></img>
                            </div>
                          )}
                          {/* {index+1}/{point.length} */}
                          <div className="p-2">
                            <div className="text-xl font-bold">
                              {exactpoints.project_title}
                            </div>
                            <p className="font-bold text-base pt-2">SDG(s)#</p>
                            <div className="flex flex-col">
                            <p className="">
                                {" "}
                                {JSON.parse(exactpoints.sdg_id).map(
                                  (sdg, index) => {
                                    return index === 0 ? sdg : `, ${sdg}`;
                                  }
                                )}
                                {JSON.parse(exactpoints.sdg_id).map((sdg, index) => {
                  const sdgName = sdgnames[index] || ""; // Get the corresponding SDG name if available
                  const sdgPair = `${sdg} - ${sdgName}`;
                  const baseUrl =
                    "https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-";

                  // Use String formatting to create the URL with the SDG number
                  const imageUrl = `${baseUrl}${sdg
                    .toString()
                    .padStart(2, "0")}.jpg`;

                  return (
                    <div className=" flex-col">

                    
                    <div>
                      
                      </div>
                    <div className=" lg:hidden">
                     {index === 0 ? `${sdgPair} \n` : `${sdgPair}`}
                     
                    </div>
                    </div>
                  );
                  //   });
                })}    
                              </p>

                            </div>
                            <p className="font-bold text-base pt-2">
                              Since 
                            </p>
                            <p>{exactpoints.start_date}</p>

                            <p className="font-bold text-base pt-2">
                              End Date 
                            </p>
                            <p className="pb-2">{exactpoints.end_date}</p>

                            <Divider />
                            <p className="font-bold text-base pt-2">
                              Project Description
                            </p>
                            <p className="truncate">
                              {exactpoints.project_description}
                            </p>
                            <div
                              className="w-full pt-2 flex justify-center items-center"
                              onClick={() => {
                                let pointData = {
                                  project_name: exactpoints.project_title,
                                  SDG_num: JSON.parse(exactpoints.sdg_id).map(
                                    (sdg, index) => {
                                      return index === 0 ? sdg : `, ${sdg}`;
                                    }
                                  ),
                                  Desc: exactpoints.project_description,
                                  status: exactpoints.project_status.toUpperCase(),
                                  url: exactpoints.project_website,
                                  team_members: exactpoints.team_members,
                                  street_address: exactpoints.street_address,
                                  country: exactpoints.country_name,
                                  funders: exactpoints.funders_title,
                                  campus: exactpoints.campus_name,
                                  project_theme: exactpoints.project_theme,
                                  project_lead: exactpoints.project_lead,
                                  estVal: exactpoints.est_project_value,
                                  projectEmail: exactpoints.project_lead_email,
                                  project_Contact:
                                    exactpoints.project_contact_info,
                                  funder_website: exactpoints.funder_website,
                                  imageid: exactpoints.image_id,
                                  id: exactpoints.id,
                                  Since: exactpoints.start_date,
                                  Until: exactpoints.end_date,
                                };

                                dispatch(setCurrentPoint(pointData));

                                let bar = document.querySelector(".popupPage");
                                let bar2 = document.querySelector(
                                  ".leaflet-popup-content-wrapper"
                                );
                                let bar3 =
                                  document.querySelector(".leaflet-popup-tip");
                                rigthbarAnimation();
                                // bar.style.display='none'
                                bar2.style.visibility = "hidden";
                                bar3.style.visibility = "hidden";
                              }}
                            >
                              <DarkButtonOnClick
                                className="popupBtn"
                                text="Read More"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                  </div>
                  ) : null;
                }).filter(Boolean)}
              </SwipeableViews>
               <MobileStepper
              variant="text"
              steps={maxSteps}
              position="static"
              activeStep={activeSteps[markerKey] || 0}
              sx={{padding:0}}
              nextButton={
                <Button
                  size="small"
                  onClick={() => handleNext(markerKey, maxSteps)}
                        disabled={activeSteps[markerKey] === maxSteps - 1}
                >
                  Next
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button size="small" onClick={() => handleBack(markerKey, maxSteps)}disabled={activeSteps[markerKey] === 0 }>
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
            </Box>
          </Popup >
          <Tooltip
            id="tools"
            direction="bottom"
            offset={[0, 0]}
            opacity={1}
            onMouseOver={showToolTip}
          >
            <span className="totalInitTool">
              <b>Total Initiatives:</b> {maxSteps}
            </span>
          </Tooltip>
        </Marker>
      );
    });
  }
}

export default function Map({ csvToArray }) {
  return (
    <MapContainer
      center={position2}
      zoom={4}
      minZoom={3}
      // style={{ height: "90vh" }}
      scrollWheelZoom={false}
    >
      <TileLayer
        accessToken="74XNMok8CtkY6OPMWhykcRrDHS8U7ARQWI71U8feEhy4w5mWyP9TQbssyncW3oop"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.jawg.io/jawg-terrain/{z}/{x}/{y}.png?access-token={accessToken}"
      />
      <ZoomControl position="topright" />
      <RenderCsvToArray />
    </MapContainer>
  );
}

function showToolTip() {
  document.getElementById("tools").style.display = "flex";
}

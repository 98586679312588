import React, { useState, useEffect } from "react";
// import { Dropdown } from "rsuite";
// import "rsuite/dist/rsuite.min.css";
import { myFunction, myFunction2 } from "./functions";
import { useSelector, useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ManagUsersDropdown = () => {
  const navigate = useNavigate()

  const authTokens = localStorage.getItem('authTokens')
  // console.log(authTokens)
  const handleManageUsersClick = () => {
    if (authTokens) {
      navigate('/manage', { replace: true });
    } else {
      // Provide a more descriptive unauthorized message
      toast.error('You are unauthorized to access that page. Please log in to continue.');
      navigate('/login', { replace: true });
    }
  };

  const handleAddUsersClick = () => {
    if(authTokens){
      navigate('/adduser', { replace: true });
    }else{
      toast.error('You are unauthorized to access that page. Please log in to continue.');
      navigate('/login',{replace:true})
    }
    
  };

  

  return (
    <div class="dropdown">
      <button onClick={myFunction} class="dropbtn flex items-center h-full">
        Manage Users
        <ArrowDropDownIcon
          className=""
          src={require("../Images/secArrow.png")}
          alt=""
        />
      </button>
      <div id="myDropdown" class="dropdown-content">
        <a onClick={handleManageUsersClick}>Manage Users</a>
        <a onClick={handleAddUsersClick}>Add New User</a>
      </div>
    </div>
  );
};


const Secbar2 = () => {
  // let isAdmin=useSelector(state=>state.User).roles_title
  const isAdmin = useSelector((state) => state.User?.roles_title);
  let isAuthenicated = useSelector((state) => state.User);
  let adminPriveleges = false;
  const authTokens = localStorage.getItem('authTokens')
  const [admin, setAdmin] = useState(adminPriveleges);
  const navigate=useNavigate()

  const handleUploadClick = () => {
    if(authTokens){
       navigate('/upload', { replace: true });
    }else{
      toast.error('You are unauthorized to access this page. Please log in to continue.');
      navigate('/login',{replace:true})
    }
   
  };
  const handleViewInitiativesClick = () => {
    if(authTokens){
       navigate('/posted', { replace: true });
    }else{
      toast.error('You are unauthorized to access this page. Please log in to continue.');
      navigate('/login',{replace:true})
    }
   
  };
  
  useEffect(() => {
    if (isAuthenicated) {
      if (
        isAdmin === "ADMINISTRATOR" ||
        isAdmin === "Admin"
      ) {
        adminPriveleges = true;
        setAdmin(adminPriveleges);
      } else {
        adminPriveleges = false;
      }
    }
  }, []);

  function downloadCSV() {
    const csvData =
      "PROJECT NAME,ADDRESS,COUNTRY,LATITUDE,LONGITUDE,CAMPUS,START DATE,END DATE,STATUS,FUNDERS,EST. PROJECT VALUE,PROJECT CONTACT INFO,PROJECT THEME,SDGs,DESCRIPTION,PROJECT LEAD NAME,PROJECT LEAD EMAIL,TEAM MEMBERS,URL,FUNDER URL";
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "data.csv");
  }
  return (
    <div className="sec-bar-2 bg-dark-red px-8 py-2">
      {admin ? <ManagUsersDropdown /> : <></>}
      <div class="dropdown">
        <div >
        <button className="dropbtn flex items-center h-full" onClick={myFunction2} class="dropbtn">
          Initiative Management
          <ArrowDropDownIcon
            className=""
            src={require("../Images/secArrow.png")}
            alt=""
          />
        </button>
        
        </div>
        <div id="myDropdown2" class="dropdown-content">
          {<a onClick={handleUploadClick}>Upload</a>}
          <a onClick={handleViewInitiativesClick}>View Initiatives</a>
          <a className="downloadCSV" onClick={downloadCSV} download="template">
            Download Template
          </a>
          <a
            href="/CCKP%20Training%20Manual.pdf"
            download="CCKP Training Manual.pdf"
          >
            Download Training Manual
          </a>


          {/* <a href="/inventory">Initiative Inventory</a> */}
        </div>
      </div>
    </div>
  );
};

export default Secbar2;

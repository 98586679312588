import React,{ useEffect, useState } from "react";
import axios from "axios";

import { saveAs } from 'file-saver';
import exportFromJSON from  'export-from-json'
import { useDispatch, useSelector } from "react-redux";
import { set_view_changes } from "../pages/reducers";
import { BASE_API_URL, UPLOAD_IMAGE_URL } from "../apiEndpoints";
//import * as XLSX from 'xlsx/xlsx.mjs';
//import * as XLSX from '../Components/xlsx.full.min.js';
var XLSX = require("xlsx");
const InventoryUpload=(props)=>{
    let dispatch=useDispatch()
    let AuthTokens=useSelector(state=>state.AuthTokens)
    const [imageLoaded, setImageLoaded] = useState(true);
    const [reloadPage, setReloadPage] = useState(true)
    const changes=useSelector(state=>state.view_inits_changes)
    useEffect(()=>{
        console.log('this works')
    },[changes])
  
    async function uploadImage(file, project_title, project_id) {
        const formData = new FormData();
    formData.append('image', file);
    formData.append('project_title', project_title);
    formData.append('project_id', project_id)

    try {
      const response = await fetch(UPLOAD_IMAGE_URL, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      // Update project object with uploaded image ID
      let updatedProject = {
        id: project_id,
        title: project_title,
        image_id: data.image_id,
      };

      // Make API call to update project object
      const projectResponse = await fetch(BASE_API_URL+`/project/${project_id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedProject),
      });

      const projectData = await projectResponse.json();

      console.log(projectData);

      // Dispatch the action to update the view changes
      window.location.reload();
        // dispatch(set_view_changes())

    } catch (error) {
      console.error(error);
    }
  }

      
      const handleUploadClick = (project_id) => {
        const fileInput = document.getElementById(`files-${project_id}`);
        uploadImage(fileInput.files[0], project_id);
    }
    const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    if (event.target.files[0]) {
      uploadImage(event.target.files[0], props.project_title, props.project_id);
    }
  
  };
    
  
    function handleImageError(e) {
        e.target.onerror = null;
        // e.target.src = defaultImageUrl; // replace this with the url of your default image
        setImageLoaded(false);
      }

    return(
        <>
        <tr id = 'tableRows'>
        <td className='zero-ref'>
      {props.project_img.length > 48 && imageLoaded ? (
        <img className='projectImageUrl' src={props.project_img} onError={handleImageError} />
      ) : (
        <form>
          <input
            className='hidden'
            id={`files-${props.project_id}`}
            type='file'
            accept='image/*'
            onChange={handleFileChange}
          />
          <label className='download-csv' htmlFor={`files-${props.project_id}`}>
            Upload Image
          </label>
          <button className='hidden' onClick={() => handleUploadClick(props.project_id)}>
            Upload
          </button>
        </form>
      )}
    </td>
  
            <td className ='frst-ref'><a className = 'mng' href="">{props.project_id}</a></td>
             <td className ='scnd-init'>{props.project_title}</td>
             <td className = 'thrd-stat'><button className={props.color}>{props.status}</button></td>
             <td className = 'frth-stat'>
                <div>
                    {/* {props.available == true ? <button class="delete-csv">Delete</button> : <button className ='add-csv'> Add</button> } */}
                    <button className = 'delete-csv'> Upload Resources</button>
                </div>
             </td>
        </tr> 
        </>
    )
}


export default InventoryUpload;
import React from 'react'

const Footer = () => {
    return <footer className="footer" id='footer-section'>
        <div className="footer-align-left">
            <p>Office of Global Affairs (OGA)<br></br>
            Regional Headquarters Building<br></br>
            The University of the West Indies Mona, Jamaica<br></br><br></br>
            Tel: (876) 977-6105<br></br>
            Fax: (876) 927-0253<br></br>
            Email: pvcglobal@uwimona.edu.jm</p>
        </div>
        {/* <div>
          <p className="footer-header">Information For</p>
          <ul className="footer-nav-list">
            <li><a href="http://my.uwi.edu/web/mycampus/home">Students</a></li>
            <li><a href="https://www.uwi.edu/alumnionline/">Alumni & Friends</a></li>
            <li><a href="https://www.uwi.edu/staff.php">Faculty & Staff</a></li>
          </ul>
        </div>
        <div>
          <p className="footer-header">Information About</p>
          <ul className="footer-nav-list">
            <li><a className="cursor-pointer" href="https://uwi.edu/gicsrd/about/">About Us</a></li>
            <li><a className="cursor-pointer" href="http://apps.uwi.edu/programmes/">Programmes</a></li>
            <li><a  className="cursor-pointer" href="https://www.uwi.edu/admissions.php">Admissions</a></li>
            <li><a  className="cursor-pointer" href="https://www.uwi.edu/administration.php">Administrations</a></li>
            <li><a  className="cursor-pointer" href="https://www.uwi.edu/campuses.php">Our Campuses</a></li>
          </ul>
        </div> */}
        <div>
          <p className="footer-header">Connect</p>
          <ul className="footer-nav-list">
            <li><a href="https://claralionelfoundation.org">Clara Lionel Foundation</a></li>
            <li><a href="https://uwi.edu/oga/">Office of Global Affairs</a></li>
          </ul>
        </div>
        {/* <div className = "footer__addr">
            The University of the West Indies<br></br>
            Regional Headquarters<br></br>
            Kingston 7, Jamaica, W.I.
            <div>
              <br></br>
          </div>
        <p className = 'first'>Tel:(876) 927-1660-9 Exts. 2336/2339<br></br></p>
            <p className = 'second'>Fax:(876) 927-0253<br></br></p>
            <p className= 'third'>Email:voffice@uwimona.edu.jm</p>
          </div>
          <ul className="footer__nav">
              <li className = "nav__item">
                  <h5 className="nav__title">
                      Information For
                  </h5>
                  <ul className="nav__ul">
              <li>
                <a href="#">Students</a>
              </li>
      
              <li>
                <a href="#">Alumni & Staff</a>
              </li>
                  
              <li>
                <a href="#">Faculty & Staff</a>
              </li>
            </ul>
          </li>
          
          <li className="nav__item nav__item--extra">
            <h5 className="nav__title">Information About</h5>
            
            <ul className="nav__ul nav__ul--extra">
              <li>
                <a href="#">About Us</a>
              </li>
              
              <li>
                <a href="#">Programmes</a>
              </li>
              
              <li>
                <a href="#">Admissions</a>
              </li>
              
              <li>
                <a href="#">Administrations</a>
              </li>
              
              <li>
                <a href="#">Our Campuses</a>
              </li>
              
            </ul>
            
            </li>
            <li className="nav__item nav__item--extra">
                <h5 className="nav__title">Connect</h5>
                
                <ul className="nav__ul nav__ul--extra">
                  <li>
                    <a href="#">Media Center</a>
                  </li>
                  
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                  
                </ul>
              </li>
          </ul> */}
        </footer>


    
  };
  
  export default Footer;
export const isLoggedIn = (state = false, action) => {
    switch (action.type) {
      case "SET_IS_LOGGED_IN":
        return action.payload; // Set the state to the value passed in the payload
      default:
        return state;
    }
  };
// reducers.js
export const setIsLoggedIn = (value) => {
    return {
      type: "SET_IS_LOGGED_IN",
      payload: value,
    };
  };
  
export const AuthTokens=(state=false,action)=>{
    switch(action.type){
        case 'setAuthTokens':
            return action.data
        default:
            return state
    }
}
export const setAuthTokens=(data)=>{
    return{
        type:'setAuthTokens',
        data
    }
}
export const User=(state=false,action)=>{
    switch(action.type){
        case 'setUser':
            return action.data
        default:
            return state
    }
}
export const setUser=(data)=>{
    return{
        type:'setUser',
        data
    }
}

export const currentPoint=(state=[],action)=>{
    switch(action.type){
        case 'Setpoint':
            return action.newpoint
        default:
            return state
    }
}
export const setCurrentPoint=(newpoint)=>{
    return{
        type:'Setpoint',
        newpoint
    }
}
export const currentInit=(state=[],action)=>{
    switch(action.type){
        case 'Setinit':
            return action.newinit
        default:
            return state
    }
}
export const setCurrentInit=(newinit)=>{
    return{
        type:'Setinit',
        newinit
    }
}
export const loggedIn=(data)=>{
    return{
        type:'LoggedIn',
        currentUser:data
        
 
    }
}
export const loggedOut=(data)=>{
    return{
        type:'LoggedOut',
        data:false
    }
}

export const currentlyPlottedPoints=(state=[],action)=>{
    switch(action.type){
        case 'PlotnewPoints':
            return action.data
        default:
            return state
    }
}
export const setCurrentPoints=(data)=>{
   return{
    type:'PlotnewPoints',
    data
   }
}
export const usersArray=(state=[],action)=>{
    switch(action.type){
        case 'Setnewusers':
            return action.data
        default:
            return state
    }
}
export const setNewusers=(data)=>{
    return{
        type:"Setnewusers",
        data
    }
}
export const initsArray=(state=[],action)=>{
    switch(action.type){
        case 'Setnewinits':
            return action.data
        default:
            return state
    }
}
export const setNewInits=(data)=>{
    return{
        type:"Setnewinits",
        data
    }
}
export const SDGs=(state=[],action)=>{
    switch(action.type){
        case "setSDGS":
            return action.data
        default:
            return state
    }
}
export const setSDGs1=(data)=>{
    return{
        type:"setSDGS",
        data
    }
}
export const pageChanges=(state=false,action)=>{
    switch(action.type){
        case 'setchanges':
            return !state
        default:
            return state
    }
}
export const setChanges=()=>{
    return{
        type:'setchanges'
    }
}
export const Email=(state=false,action)=>{
    switch(action.type){
        case 'email':
            return action.data
        default:
            return state
    }
}
export const setEmail=(data)=>{
    return{
        type:'email',data
    }
}
export const ResName=(state=false,action)=>{
    switch(action.type){
        case 'resource':
            return action.data
        default:
            return state
    }
}
export const setResName=(data)=>{
    return{
        type:'resource',data
    }
}
export const ProjectID = (state=false,action)=>{
    switch(action.type){
        case 'projectid':
            return action.data
        default:
            return state
    }
}
export const setProjectID = (data)=>{
    return{
        type:'projectid',data
    }
}
export const editUserData=(state=false,action)=>{
    switch(action.type){
        case 'setEditUser':
            return action.data
        default:
            return state
    }
}
export const setEditUser=(data)=>{
    return{
        type:'setEditUser',data
    }   
}

export const filterCount=(state=[], action)=>{
    switch(action.type){
        case 'setFilterCount':
            return action.data
        default:
            return state
    }
}
export const setFilterCount=(data)=>{
    return{
        type:'setFilterCount',data
    }
}
export const editInitData=(state=false,action)=>{
    switch(action.type){
        case 'setInitData':
            return action.data
        default:
            return state
    }
}
export const setInitData=(data)=>{
    return{
        type:'setInitData',data
    }   
}
export const sdgNames=(state=[],action)=>{
    switch(action.type){
        case 'setSDGNames':
            return action.data
        default:
            return state
    }
}
export const setSDGNames=(data)=>{
    return{
        type:'setSDGNames',data
    }
}

export const view_inits_changes=(state=false,action)=>{
    switch(action.type){
        case "newchanges":
            return !state
        default:
            return state
    }
}
export const set_view_changes=()=>{
    return{
        type:"newchanges"
    }
}
export const view_resources_changes=(state=false,action)=>{
    switch(action.type){
        case "newreschanges":
            return !state
        default:
            return state
    }
}
export const set_view_resources_changes=()=>{
    return{
        type:"newreschanges"
    }
}

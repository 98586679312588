import React, { useState, useEffect, useRef } from "react";

export default function TokenExpirationPopup({ closeToast, onBackToLogin }) {
  const [showPopup, setShowPopup] = useState(false);

  const handleClose = () => {
    setShowPopup(false);
    closeToast();
    if (onBackToLogin) {
      onBackToLogin();
    }
  };

  return (
    <div>
      
        <div className="">
          <div id="">
            <div id="">
              <p className="popUpText">
                Your session has expired. Please log in again.
              </p>
              <button className="alert_button" onClick={handleClose}>
                OK
              </button>
            </div>
          </div>
        </div>

    </div>
  );
}

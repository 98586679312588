import React, { useState,useEffect } from "react";

import {convertArraystoOneArray} from './datamangers'
import { sdgArrayConversion } from "./datamangers";
//import { getSdg } from "../pages/Map";
import { motion } from "framer-motion";


import {useSelector,useDispatch} from 'react-redux'

import { setCurrentPoints, setFilterCount, setSDGNames } from "../pages/reducers";
import { DYNAMIC_FILTER_URL, GET_PROJECTS_URL, GET_SDGS_URL } from "../apiEndpoints";
import { toast } from "react-toastify";
import FilterPopup from "./filterPopup";

const Sidebar2=(props)=>{
    let dispatch=useDispatch()
    const [countries,setCountries]=useState([])
    const [sdgs,setSDGs]=useState([])
    const [statuses,setStatus]=useState([])
    const [themes, setTheme]=useState([])
    const [count, setCount] = useState()
    const AuthTokens=useSelector(state=>state.AuthTokens)
    async function GetAllPoints(){

            const getData=async()=>{ 
             let response= await fetch(GET_PROJECTS_URL,{
                    method:'GET',
                })
                let data=await response.json()
                if(response.status===200){
                    dispatch(setCurrentPoints(data))
                    dispatch(setFilterCount(data.length))
                    
                }else{
                   console.log("bugged oout")
                }
          }
            document.querySelector('#filter-Name').value=''
            document.querySelector('#sdgs').value = '0';

            // Reset the value of the second <select> element (Country) to "0"
            document.querySelector('#Country-filter').value = '0';

            // Reset the value of the third <select> element (Status) to "0"
            document.querySelector('#states').value = '0';

            // Reset the value of the fourth <select> element (Theme) to "0"
            document.querySelector('#theme').value = '0';

            document.querySelector('#filter-Name1').value=''
          getData() 
          toast(<FilterPopup/>)
         
    }
   
    async function DynamicQuery() {
        let arr = [];
    
        const Country=document.querySelector('#Country-filter').value
            const SDG=document.querySelector('#sdgs').value
            const theme=document.querySelector('#theme').value
            const status=document.querySelector('#states').value
            const funders=document.querySelector('#filter-Name1').value
    
        const initial_query = {
            'country_name__iexact': Country,
            'funders_title__contains': funders,
            'project_status__iexact': status,
            'project_theme__icontains': theme,
        };
    
        let query = {};
        let keys = [
            "country_name__iexact",
            "funders_title__contains",
            "project_status__iexact",
            "project_theme__icontains",
        ];
    
        for (let i = 0; i <= keys.length - 1; i++) {
            if (initial_query[keys[i]] !== "0") {
                query[keys[i]] = initial_query[keys[i]];
            }
        }
    
        let response = await fetch(DYNAMIC_FILTER_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(query),
        });
    
        let data = await response.json();
    
        // Filter based on SDGs
        const sdgFilteredData = data.filter(project => {
          const projectSDGs = JSON.parse(project.sdg_id);
          return SDG === '0' || projectSDGs.includes(SDG.trim());
      });
      
      console.log(sdgFilteredData)
      // Apply additional filters using initial_query
        if (response.ok) {
            dispatch(setCurrentPoints(sdgFilteredData));
                    dispatch(setFilterCount(sdgFilteredData.length));
                    hidePopouts();
                    toast(<FilterPopup />);
        } else {
            console.error('Error fetching data:', response.statusText);
        }
    
        // console.log(data);
    }
    
    
    function getValue(selector) {
        return document.querySelector(selector).value;
    }
    
    function hidePopouts() {
        for (let i = 2; i <= 5; i++) {
            document.getElementById(`pop-out-${i}`).style.display = 'none';
        }
    }
    
      
      
    
    


    useEffect(()=>{
        const getsdgData=async()=>{
          let response =await fetch(GET_SDGS_URL,{
            method: 'GET',
          })
          let data=await response.json()
          if (response.status===200){
            setSDGs(data)
            // console.log(data)
            document.getElementById('pop-out-2').style.display='none'
            dispatch(setSDGNames(data))
          }
            else{
              console.log('error here')
            }
          }
          getsdgData()
          
      },[])

      async function getProjName(){
        let projName=document.getElementById('filter-Name').value
        console.log(projName)
        let test={'project_title__contains':projName}
            const getData=async()=>{ 
              let response= await fetch(DYNAMIC_FILTER_URL,{
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                },
                body:JSON.stringify(test)
                })
                 let data=await response.json()
                 if(response.status===200){
                  console.log(data)
                    dispatch(setCurrentPoints(data))
                    dispatch(setFilterCount(data.length))
                    document.getElementById('pop-out-1').style.display='none'
                     
                 }else{
                    console.log("bugged oout")
                 }
                 toast(<FilterPopup/>)
           }   
          getData()
       
      }


 

    return(
        <div className="pop-out-bar ">
            <ul className="pop-out-icons">
                <li className="pop-out">
                    <div className="pop-out-icon" onClick={()=>animateButton("pop-out-1")}>
                        <img className='row-img'src={require('../Images/search.png')} alt = '' />
                            Search
                    </div>
                    <motion.div  className="pop-out-filter" id="pop-out-1">
                        <p className="filter-title">Search</p>
                        <div className="dropdown-filter-section">
                            <div>
                                <input id = 'filter-Name'></input>
                            </div>
                            <div>
                                <button className='searchfil' onClick={getProjName}>Search</button>
                            </div>
                            
                        </div>
                         
                    </motion.div>
                </li>
                <li className="pop-out" >
                    <div className="pop-out-icon" onClick={()=>animateButton("pop-out-2")}>
                        <img src={require('../Images/Status.png')} alt = '' />
                           SDGs
                    </div>
                    <motion.div className="pop-out-filter" id="pop-out-2">
                        <p className="filter-title">Sustainable Development Goals (SDGs)</p>
                        <div className="dropdown-filter-section">
                            <div>
                                <select id="sdgs" name="campus">
                                <option value="0">-select an option-</option>
                                {sdgs.map(function(sdg,index){
                        return <option value={sdg.sdg_id}>{sdg.sdg_id}. {sdg.sdg_name}</option>
                    })}
                                </select>
                            </div>
                            <div>
                                <button onClick={DynamicQuery}>Filter</button>
                            </div>
                            
                        </div>
                         
                    </motion.div>
                </li>
                <li className="pop-out">
                    <div className="pop-out-icon" onClick={()=>animateButton("pop-out-3")}>
                        <img src={require('../Images/Country.png')} alt = '' />
                            Country
                    </div>
                    <motion.div className="pop-out-filter" id="pop-out-3">
                        <p className="filter-title">Country</p>
                        <div className="dropdown-filter-section">
                            <div>
                            <select id="Country-filter" name="campus">
                                <option value="0">-select an option-</option>
                                    {/* {countries.map(function(country,index){
                                    return <option value={country}>{country}</option>
                                })} */}
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Brazil">Brazil</option>
                                <option value="Brunei">Brunei</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cabo Verde">Cabo Verde</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Central African Republic">Central African Republic</option>
                                <option value="Chad">Chad</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo (Congo-Brazzaville)">Congo (Congo-Brazzaville)</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Countries">Countries</option>
                                <option value="Croatia">Croatia</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czechia (Czech Republic)">Czechia (Czech Republic)</option>
                                <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">Dominican Republic</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Eswatini">Eswatini (fmr. "Swaziland")</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Greece">Greece</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Holy See">Holy See</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Iran">Iran</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Laos">Laos</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon">Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libya">Libya</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">Marshall Islands</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Micronesia">Micronesia</option>
                                <option value="Moldova">Moldova</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montenegro">Montenegro</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar (formerly Burma)">Myanmar (formerly Burma)</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="North Korea">North Korea</option>
                                <option value="North Macedonia">North Macedonia</option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Palestine State">Palestine State</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">Papua New Guinea</option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Romania">Romania</option>
                                <option value="Russia">Russia</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                <option value="Saint Lucia">Saint Lucia</option>
                                <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                <option value="Samoa">Samoa</option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                <option value="Saudi Arabia">Saudi Arabia</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Serbia">Serbia</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra Leone">Sierra Leone</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Slovakia">Slovakia</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">Solomon Islands</option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Africa">South Africa</option>
                                <option value="South Korea">South Korea</option>
                                <option value="South Sudan">South Sudan</option>
                                <option value="Spain">Spain</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syria">Syria</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania">Tanzania</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Timor-Leste">Timor-Leste</option>
                                <option value="Togo">Togo</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">Turkmenistan</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="United States of America">United States of America</option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Vietnam">Vietnam</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                            </div>
                            <div>
                                <button onClick={DynamicQuery}>Filter</button>
                            </div>
                            
                        </div>
                         
                    </motion.div>
                </li>
                <li className="pop-out">
                    <div className="pop-out-icon" onClick={()=>animateButton("pop-out-4")}>
                        <img src={require('../Images/SDGs.png')} alt = '' />
                            Theme
                    </div>
                    <motion.div className="pop-out-filter" id="pop-out-4">
                        <p className="filter-title">Theme</p>
                        <div className="dropdown-filter-section">
                            <div>
                            <select id="theme" name="campus">
                            <option value="0">-select an option-</option>
                            <option value='Disaster Resilience'>Disaster Resilience</option>
                            <option value = 'Climate Change'> Climate Change</option>
                            <option value = 'Climate Justice'>Climate Justice</option>
                            <option value = 'Sustainability Management'> Sustainability Management</option>
                            <option value = 'Environmental Resilience'> Environmental Resilience</option>
                            <option value = 'Sustainable Livelihoods'> Sustainable Livelihoods</option>
                            <option value = 'Natural Based Solutions and Biodiversity'> Natural Based Solutions and Biodiversity</option>
                            </select>
                            </div>
                            <div>
                                <button onClick={DynamicQuery}>Filter</button>
                            </div>
                            
                        </div>
                         
                    </motion.div>
                </li>
                <li className="pop-out">
                    <div className="pop-out-icon" onClick={()=>animateButton("pop-out-5")}>
                        <img src={require('../Images/Other.png')} alt = '' />
                        Other
                    </div>
                    <motion.div className="pop-out-filter1" id="pop-out-5">
                        <p className="filter-title">Status</p>
                        <div className="dropdown-filter-section1">
                            <div>
                                <select id="states" name="campus">
                                    <option value="0">-select an option-</option>  
                                    <option value='ONGOING'>ONGOING</option>
                                    <option value='COMPLETE'>COMPLETE</option>
                                </select>
                            </div>
                            <div>
                                <button onClick={DynamicQuery}>Filter</button>
                            </div>
                        </div>
                        <p className="filter-title">Funders</p>
                        <div className="dropdown-filter-section">
                            <div>
                                <input id = 'filter-Name1'></input>
                            </div>
                            <div>
                                <button className='searchfil' onClick={DynamicQuery}>Search</button>
                            </div>
                        </div>
                    </motion.div>
                </li>
                <li className="pop-out"  id="other_filter">
                    <div className="pop-out-icon">
                        <button className='clearFilt' onClick={GetAllPoints}> Clear Filters </button>
                    </div>
                </li>
            </ul>
        </div>
    )

function animateButton(buttonID){
    const button=document.getElementById(buttonID)
    if(button.style.display==='block'){
        let filter_width=350
        button.style.width=filter_width+'px'
        setTimeout(()=>{
            let filter_anim= setInterval(()=>{
                filter_width-=5
                button.style.width=filter_width+'px'
                if(filter_width<=0){
                    clearInterval(filter_anim)
                    setTimeout(()=>{
                        button.style.display='none'
                    },10)
                }
            })
        })   
    }   
    else{
        let filter_width=0
        button.style.width=0+'px'
        button.style.display='block'
        setTimeout(()=>{
            let filter_anim= setInterval(()=>{
                filter_width+=5
                button.style.width=filter_width+'px'
                if(filter_width>=350){
                    clearInterval(filter_anim)
                }
            })
        },10) 
    }
}
}

export default Sidebar2;
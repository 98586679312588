import * as React from "react";
import Button from "@mui/material/Button";

const MUI_STYLE = {
  boxShadow: "none",
  fontFamily: "inherit",
  // fontWeight: "inherit",
  padding: "4px 8px",
  borderRadius: "2px",
  textTransform: 'none',
  backgroundColor: "#ba2f29",
  "&:hover": {
    backgroundColor: "#a2221e",
  }
};

const MUI_STYLE_LG = {
  boxShadow: "none",
  fontFamily: "inherit",
  fontSize: "20px",
  padding: "4px 8px",
  borderRadius: "2px",
  textTransform: 'none',
  backgroundColor: "#ba2f29",
  "&:hover": {
    backgroundColor: "#a2221e",
  },
  padding: "12px 8px"
};

export const DarkButton = ({ text, url }) => {
  return (
    <Button sx={MUI_STYLE} variant="contained" href={url}>
      {text} 
    </Button>
  );
};

export const DarkButtonOnClick = ({ text }) => {
  return (
    <Button sx={MUI_STYLE} variant="contained" >
      {text} 
    </Button>
  );
};

export const DarkButtonLargeExternal = ({ text, url }) => {
  return (
    <Button sx={MUI_STYLE_LG} variant="contained" href={url}>
      <span className="whitespace-nowrap">{text}</span>
      <img className = 'img-init'src={require('../../Images/Group_120.png')}></img>
    </Button>
  );
};


function User(first_name,last_name,email,campus_name,roles_title,status,password){
    this.first_name=first_name
    this.last_name=last_name
    this.email=email
    this.roles_title=roles_title
    this.campus_name=campus_name
    this.status=status
    this.password=password
}
export function convertToArrayofObjects(array){
   let newarray=[]
    for(let i=0;i<=array.length-1;i++){
        let newuser= new User(array[i][1],array[i][2],array[i][3],array[i][4],array[i][5],array[i][6],array[i][7])
        newarray.push(newuser)
    }
    return newarray
}
   

function Inits(project_id, project_title, project_status){
    this.project_id=project_id
    this.project_title = project_title
    this.project_status = project_status
}

export function convertToArrayOfObjects2(array){
    let newarray1 = []
    for (let i = 0; i<=array.length-1;i++){
        let newinit = new Inits(array[i][0],array[i][1],array[i][2])
        newarray1.push(newinit)
    }
    console.log(newarray1)
    return newarray1
    
}
export function convertArraystoOneArray(array){
    let newarr=[]
    for(let i=0;i<=array.length-1;i++){
        newarr.push(array[i][0])
    }
    return newarr
}
export function sdgArrayConversion(arr){
    let newarr=[]
    for(let i=0;i<=arr.length-1;i++){
        let obj={
           sdg_title: arr[i][1],
           sdg_id:arr[i][0]
        }
        newarr.push(obj)
    }
    return newarr
}

 export function sortbySame(arr){
    let newArr=[]
    let dups=[]
    for(let i=0;i<=arr.length-1;i++){
        if(i in dups){
            continue
        }
        else{
            let samearr=[]
            for(let x=i;x<=arr.length-1;x++){
                if(x in dups){
                    continue
                }
               else{
                    if(arr[x].Latitude==arr[i].Latitude && arr[x].Longitude==arr[i].Longitude){
                        samearr.push(arr[x])
                        dups.push(x)
                    }
               }
            }
            if(samearr.length>1){
                newArr.push(samearr)
                
            }
            
           
        }
    }
    return newArr
}
export const groupBy = (array,key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };
export const convertObjecttoArray=(obj)=>{
    let arr=[]
    let obj2=Object.values(obj) 
    obj2.forEach((point)=>arr.push(point))
    return arr
   
}
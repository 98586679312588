import React from "react";
import Map from "./Map";
import Sidebar from "./sidebar";
import Popup1 from "./popup";
const Overlay=()=>{
    return(
       <div className="overlay">
            <Popup1/>
       </div>
    )
}
export default Overlay
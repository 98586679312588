
import { setIsLoggedIn } from "../pages/reducers";


const logoutUser = () => {
  try {
    // Remove the authentication tokens from localStorage
    localStorage.removeItem("authTokens");
    // localStorage.removeItem("refreshToken");

    // Optionally, you can also clear any other user-related data from localStorage
    // localStorage.removeItem("otherUserData");

    console.log("Logout Successful");
    // Return a success indicator or any relevant data
    return { success: true, message: "User logged out successfully" };
  } catch (error) {
    // Handle any potential errors, such as localStorage access issues
    console.error("Logout failed:", error.message);
    return { success: false, message: "Logout failed" };
  }
};

export default logoutUser;

import Navbar from "../Components/navbar";
import Footer from "../Components/Footer";
import Secbar2 from "../Components/secbar2";
import TestUpload from "../Components/paginationUpload";
import { convertToArrayOfObjects2 } from "../Components/datamangers";
import withTokenExpiration from "../Components/withTokenExpiration";
import { useState,useEffect } from "react";
import {useSelector,useDispatch} from 'react-redux'
import logoutUser from "../Components/logoutUser";

import {toast} from 'react-toastify'
import { useNavigate } from "react-router-dom";


const PostedInitiatives = () => {


  const [showInactivityPopup, setShowInactivityPopup] = useState(false);
  const logged=useSelector(state=>state.isLoggedIn)
  let authTokens = localStorage.getItem('authTokens')
  let isAuthenicated = useSelector((state)=>state.User)
  
  
  return (
    <div>
      <Navbar />
      {isAuthenicated&&authTokens&&<Secbar2 />}
      <div className="px-8 py-4">
        <h1 className="text-xl font-semibold">Posted Initiatives</h1>

        <TestUpload />
      </div>
      
      <Footer />
    </div>
  );
};

export default PostedInitiatives;

function myFunction4() {
  document.getElementById("myDropdown4").classList.toggle("show");
}

// Close the dropdown menu if the user clicks outside of it
window.onclick = function (event) {
  if (!event.target.matches(".filter")) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
  }
};

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { set_view_resources_changes } from "../pages/reducers";
import { toast } from "react-toastify";
import { BASE_API_URL, DELETE_PROJECT_RESOURCE } from '../apiEndpoints';


export default function DeleteResourcePopup({ closeToast }) {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.Email);
  const AuthTokens = useSelector((state) => state.AuthTokens);
  const resName = useSelector(state=>state.ResName)
  async function deleteResources(){
    // console.log(docName)
    let test = {'document_Name':resName}
    console.log(test)
    const getData=async()=>{ 
        let response= await fetch(DELETE_PROJECT_RESOURCE,{
          method:'POST',
          headers:{
              'Content-Type':'application/json',
              'Authorization':'Bearer '+ String(AuthTokens.access)
          },
          body:JSON.stringify(test)
          })
           let data=await response.json()
           if(response.status===200){
            // console.log(data)
            dispatch(set_view_resources_changes())
           }else{
              console.log("bugged oout")
              toast('Failed to delete',{type:'error'})
           }
     }   
    getData()
}

  const handleYesClick = () => {
    deleteResources();
  };

  const handleNoClick = () => {
    closeToast(); // Close the toast if the user clicks "No"
  };

  return (
    <div>
      <div className="">
        <div id="">
          <div id="">
            <p className="popUpText">
              Are you sure you want to delete this resource?
            </p>
            <p>Clicking YES will remove this resource</p>
            <button className="alert_button1" onClick={handleYesClick}>
              Yes
            </button>
            <button className="alert_button2" onClick={handleNoClick}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmPopup4 from "../Components/confirmPopup4";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import Paper from "@mui/material/Paper";
import { FORGOT_PASSWORD_URL } from "../apiEndpoints";
const ForgotPassword = () => {
  //localStorage.getItem('authTokens')

  let navigate = useNavigate();
  const [error, setError] = useState("");
  let dispatch = useDispatch();

  const handleBackToLoginClick = () => {
    // Perform navigation to the login page
    navigate("/login");
  };

  function update_password() {
    let email = document.querySelector("#email1").value;
    // let previouspassword = document.querySelector('#prev-pword').value
    let info = { email: email };
    const forgotPassword = async () => {
      let response = await fetch(
        FORGOT_PASSWORD_URL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(info),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        // console.log(data)
        // document.getElementById('helper8').style.display = 'flex';
        toast(<ConfirmPopup4 onBackToLoginClick={handleBackToLoginClick} />, {
          // Toast options here
        });
      } else {
        console.log("bugging");
      }
    };
    forgotPassword();
  }
  return (
    <div className="Login">
      <div className="Login-top">
        <h1 className="text-lg font-semibold">CCKP PORTAL</h1>
      </div>
      <div className="w-max mx-auto">
        <div className="my-10 ">
          <div className="mb-6"><Box
            sx={{
              //   display: "flex",
              display: "inline-block",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                //   width: 128,
                //   height: 128,
              },
            }}
          >
            <Paper elevation={3}>
              <div className="forgot-center">
                <div className="forgot_form">
                  <h1>Forgot Password</h1>
                  <div className="email-div">
                    <label>Email</label>
                    <input
                      type={"email"}
                      placeholder={"johndoe@gmail.com"}
                      id="email1"
                    />
                  </div>
                  <button
                    className="forgot-form-btn"
                    onClick={() => update_password()}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </Paper>
          </Box></div>
          <div className="text-center">
            <Link to="/" className="">
              Go back home
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ForgotPassword;

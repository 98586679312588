import React, { useEffect, useState } from 'react';
import { setChanges } from "../pages/reducers";
import Footer from '../Components/Footer';
import Navbar from '../Components/navbar';

import Secbar2 from '../Components/secbar2';

import { useNavigate } from "react-router-dom";
import {useSelector,useDispatch} from 'react-redux'
import UpdateUserPopup from '../Components/updateUserPopup';
import {convertToArrayofObjects} from '../Components/datamangers'
import { toast } from "react-toastify";
import TokenExpirationPopup from '../Components/tokenPopup';
import EditUserPopUp from '../Components/editUserPopup'
import { EDIT_USER_URL } from '../apiEndpoints';
const EditUser=()=>{
    let AuthTokens=useSelector(state=>state.AuthTokens)
    let authTokens = localStorage.getItem('authTokens')
  let isAuthenicated = useSelector((state)=>state.User)
    let dispatch=useDispatch()
    const handleBackToLogin = () => {
        // Perform navigation to the login page
        navigate("/login");
      };
    const userTobeEdited=useSelector(state=>state.editUserData)
    let [fname,setName]=useState(userTobeEdited.firstname)
    let [lname,setLName]=useState(userTobeEdited.lastname)
    let [uemail,usetEmail]=useState(userTobeEdited.email)
    let prevemail=userTobeEdited.email
    const currentPoint=useSelector(state=>state.isLogged)
    let Currentuser=JSON.parse(sessionStorage.getItem('logged'))
    let [error,seterror]=useState("")
    const navigate=useNavigate()
    async function EditUserfunc(){
        const handleBackToManageUsers = () => {
            // Perform navigation to the login page
            navigate("/manage");
          };
        const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        let fname=document.querySelector('#fname').value
        let lname=document.querySelector('#lname').value
        let email=document.querySelector('#emailsign').value
        // let prevemail = document.querySelector('#prevemailsign').value
        let campus_name=document.querySelector('#campuses').value
        let roles_title=document.querySelector('#stype').value
        if(fname===''||email===''||lname===''||campus_name==='0'|| roles_title==='0'){
            toast.error("Please ensure there are no empty fields.")
        }
        else if(!emailPattern.test(email)){
            seterror("Invalid email")
        }
        else{
            let user= { 'firstname': fname,
                        'lastname': lname,
                        'email': email,
                        'prevemail': prevemail,
                        'campus': campus_name,
                        'role': roles_title,
                        // 'status':Currentuser.status
        }
        let response = await fetch(EDIT_USER_URL,{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+ String(AuthTokens.access)
            },
            body:JSON.stringify(user)
        })
        let data=await response.json()
        if(response.status===200){
         console.log(data)
         dispatch(setChanges())
         toast(<EditUserPopUp onBackToManageUsers={handleBackToManageUsers}/>)

        }else if(response.status === 401){
            toast(<TokenExpirationPopup onBackToLogin={handleBackToLogin}/>)
            navigate('/login',{replace:true})
        }else{
           console.log("bugged oout")
           toast.error('An error has occurred')
        }
        }
        

       
        
    }
    console.log(userTobeEdited)

    return(
    <div>
        <div>
            <Navbar/>
            {isAuthenicated&&authTokens&&<Secbar2/>}
            {/* <TokenExpirationPopup/> */}
        <body className = 'usrBody'>
            <h1 className = 'usrH1 text-xl font-semibold'>
                Edit User
            </h1>
            <div>
            <p className = 'add_usr'>
                Enter the details below necessary to create a new user.<br></br>
                Once created, a default password will be sent to user after which<br></br>
                they may log in an change.
            </p>
        </div>
        <div>
                <div className ='a-name'>
                    <label for="fname">First Name</label>
                    <label for="lname" className = 'lname'>Last Name</label>
                </div>
                <div className ='fl-input'>
                    <input type="text" id='fname' value={fname} onChange={(e) => setName(e.target.value)}></input>
                    
                    <input type="text" value={lname} onChange={(e) => setLName(e.target.value)} id='lname'></input>
                </div>
                
                <div className = 'email'>
                    <label for = 'email'>Email</label>
                </div>
                <div className ='e-input'>
                    <input type ='text' value={uemail} onChange={(e) => usetEmail(e.target.value)} id='emailsign'></input>
                    <p></p> 
                </div>
                <div className = 'schools'>
                    <label for="campus">UWI Campus</label>
                </div>
                <div className = 's-campus'>
                    <select id="campuses" name="campus">
                        <option value={userTobeEdited.campus}>{userTobeEdited.campus}</option>
                        {userTobeEdited.campus!=='Mona'?<option value="Mona">Mona</option>:<span></span>}
                        {userTobeEdited.campus!=='St. Augustine'?<option value="St. Augustine">St. Augustine</option>:<sppan></sppan>}
                        {userTobeEdited.campus!=='Cave Hill'?<option value="Cave Hill">Cave Hill</option>:<span></span>}
                        {userTobeEdited.campus!=='Five Islands'?<option value="Five Islands">Five Islands</option>:<span></span>}
                        {userTobeEdited.campus!=='UWI Center'?<option value="UWI Center">UWI Centre</option>:<span></span>}
                        {userTobeEdited.campus!=='Open Campus'?<option value="Open Campus">Open Campus</option>:<span></span>}
                      </select>
                </div>
                <div className = 'utypes'>
                    <label for="utype">User Type</label>
                </div>
                <div className = 's-type'>
                    <select id="stype" name="utype">
                         <option value={userTobeEdited.usertype}>{userTobeEdited.usertype}</option>
                         {userTobeEdited.usertype!=='ADMINISTRATOR'?<option value="ADMINISTRATOR">ADMINISTRATOR</option>:<span></span>}
                         {userTobeEdited.usertype!=='DATA CONTRIBUTOR'?<option value="DATA CONTRIBUTOR">DATA CONTRIBUTOR</option>:<span></span>}
                      </select>
                </div>
            <button className = 'change-u' onClick={EditUserfunc}>
                Update
            </button>
            <button className ='cancel' onClick={()=>navigate('/manage', {replace: true})}>
                Cancel
            </button>
        </div>
        </body>
        <Footer/>

        </div>
        </div>
    )
}
export function User(first_name,last_name,email,campus_name,roles_title,status){
    this.first_name=first_name
    this.last_name=last_name
    this.email=email
    this.roles_title=roles_title
    this.campus_name=campus_name
    this.status=status
}


export default EditUser;
import React from "react";

export default function ConfirmPopup({ closeToast }) {
  const handleClose = () => {
    closeToast();
  };

  return (
    <div>
      <div className="">
        <div id="">
          <div id="">
            <p className="popUpText">User's password has been changed.</p>
            <button className="alert_button" onClick={handleClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";

export default function AddUserPopUp({ closeToast, onBackToManageUsers }) {
  const handleClose = () => {
    closeToast(); // Close the toast when "OK" is clicked
    // Call the callback function to navigate
    if (onBackToManageUsers) {
        onBackToManageUsers();
    }
  };

  return (
    <div>
      <div className="">
        <div id="">
          <div id="">
            <p className="popUpText">
              The user has been created
            </p>
            <button className="alert_button" onClick={handleClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

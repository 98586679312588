import Navbar from "../Components/navbar";
import React from "react";
import Footer from "../Components/Footer";
import Secbar2 from "../Components/secbar2";
// import TestPage from '../Components/ManageUsersTable';
import { setNewusers } from "./reducers";

//import { convertToArrayOfObjects } from "../Components/datamangers";
import { convertToArrayofObjects } from "../Components/datamangers";
import { useSelector, useDispatch } from "react-redux";

import PopupRows from "../Components/managePopup";
import PopupRowsDeac from "../Components/managePopupDeac";
import DeletePopUp from "../Components/deletePopup";
import ManageUserTable from "../Components/ManageUsersTable";
import { FILTER_USERS_URL, GET_USERS_URL } from "../apiEndpoints";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";


const ManageUser = () => {
  const AuthTokens = useSelector((state) => state.AuthTokens);
  let isAuthenicated=useSelector(state=>state.User)
  const authTokens=localStorage.getItem('authTokens')
  let dispatch = useDispatch();
  async function getUsrNames() {
    let searchName = document.getElementById("getSearch").value;
    if (searchName === "") {
      const getData = async () => {
        let response = await fetch(
          GET_USERS_URL,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + String(AuthTokens.access),
            },
          }
        );
        let data = await response.json();
        if (response.status === 200) {
          console.log(data);
          dispatch(setNewusers(data));
        } else {
          console.log("bugged oout");
        }
      };
      getData();
    } else {
      let test = { name: searchName };
      const getData = async () => {
        let response = await fetch(
          FILTER_USERS_URL,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + String(AuthTokens.access),
            },
            body: JSON.stringify(test),
          }
        );
        let data = await response.json();
        if (response.status === 200) {
          dispatch(setNewusers(data));
        } else {
          console.log("bugged oout");
        }
      };
      getData();
    }
  }
  return (
    <div>
      <Navbar />
      {isAuthenicated&&authTokens&&<Secbar2 />}
      <div className="px-8 py-4">
        <h1 className="text-xl font-semibold"> Manage Users</h1>
        <p className="pb-6">
          View all users, toggle their status, edit profiles, delete accounts, change passwords, and search by first name.
        </p>

        {/* border border-gray-600 rounded-md */}
        <div className="border rounded-sm border-grey-500 mb-2 py-2 px-2 ">
          <input
            style={{ width: "100%" }}
            type="search"
            placeholder="Search"
            id="getSearch"
            onInput={getUsrNames}
          ></input>
        </div>
        <div className="background">
          <PopupRows />
          <PopupRowsDeac />
          {/* <DeletePopUp/>  */}
        </div>
        <div className="">
          <ManageUserTable />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ManageUser;

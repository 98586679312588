import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../apiEndpoints";
import { Divider, IconButton } from "@mui/material";
import { DarkButton, DarkButtonOnClick } from "./MUI_Components/button";
import Button from "@mui/material/Button";
import { setCurrentInit } from "../pages/reducers";

const FullPage = () => {
  const currentPoint = useSelector((state) => state.currentPoint);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(currentPoint);
  return (
    <div className="diving">
      <div className="topSec bg-green-500" id={currentPoint.status}>
        <h1 className="currentP">{currentPoint.status}</h1>
        <button className="close-btn" onClick={rigthbarAnimation}>
          x
        </button>
      </div>
      <div className="overflow-y-auto h-[450px]">
        {currentPoint.imageid != null ? (
          <div className="flexing">
            <img
              className="markerImg3"
              src={
                BASE_API_URL +
                `/media/project_${currentPoint.id}/${currentPoint.id}.jpg`
              }
            ></img>
          </div>
        ) : (
          <img
            className="markerImg3"
            src={require("../Images/default_image.jpeg")}
          ></img>
        )}

        <div className="">
          <div className="">
            <div className="pt-2 pl-2 pr-2">
              <p className="font-bold text-lg">{currentPoint.project_name}</p>
            </div>
            <div className="flex flex-col text-base">
              <div className="p-2 flex flex-col gap-2">
                <p>
                  {/* {console.log( currentPoint.SDG_num.toString().split(",, ") )} */}
                  {/* {
                (currentPoint.SDG_num).split(",").map(num) => (
                    return(
                        <p>here</p>
                    )
                )
                
                }
                 */}
                  {/* {
                    currentPoint.SDG_num.toString().split(",, ").map(num) = (
                        <div>
                            <p>here</p>
                        </div>
                    )
                    
                 } */}
                  <div className="flex justify-center items-center pt-2">
                    {currentPoint.SDG_num ? (
                      <div className="flex gap-2">
                        {currentPoint.SDG_num.toString()
                          .split(",, ")
                          .map((num, index) => (
                            <div>
                              {/* {console.log("https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-" +
                                  num +
                                  ".jpg")} */}
                              <img
                                className="flex flex-row rounded-sm w-16 h-16"
                                src={
                                  "https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-" +
                                  num.trim().padStart(2, "0") +
                                  ".jpg"
                                }
                                alt={`SDG ${num}`}
                              />
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="pt-2 pb-2"></div>
                  <Divider />
                </p>
                <div className="flex gap-6 justify-center items-center">
                  <div className="flex flex-col gap-1 justify-center items-center">
                    <p className="font-bold">Since </p>
                    <p>{" " + currentPoint.Since}</p>
                  </div>
                  <div className="flex flex-col gap-1 justify-center items-center">
                    <p className="font-bold">Until </p>
                    <p>{" " + currentPoint.Until}</p>
                  </div>
                </div>
                <Divider />
                <div className="flex flex-col">
                  <p className="font-bold ">Theme </p>
                  <p className="">{" " + currentPoint.project_theme}</p>
                </div>
                <div className="flex flex-col ">
                  <p className="font-bold">Address </p>
                  <p>
                    {" " +
                      currentPoint.street_address +
                      ", " +
                      currentPoint.country}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Campus </p>
                  <p>{" " + currentPoint.campus}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Funders </p>
                  <p>{" " + currentPoint.funders}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Project Lead</p>
                  <p>{" " + currentPoint.project_lead}</p>
                </div>
                <div className="flex flex-col">
                  <p className="font-bold">Team Members</p>
                  <p>{" " + currentPoint.team_members}</p>
                </div>
                
                {/* <div className="flex gap-1">
                  <p className="font-bold">Project Value: </p>
                  <p> ${currentPoint.estVal}</p>
                </div> */}
                {/* <p className="itemTest1">
                  <b>Project Contact</b> : {currentPoint.project_Contact}
                </p>
              </div>
              <div></div>
              <div>
                <p className="descSection">
                  <b>Project Value </b>: ${currentPoint.estVal}
                </p>
              </div> */}
              </div>
              <Divider />
              <div className="p-2">{currentPoint.Desc}</div>
              <div className="p-2 flex justify-center items-center">
                {/* {console.log(currentPoint)} */}
                {/* <div
                  onClick={() => {
                    navigate("/fullpage", { replace: true });
                    dispatch(setCurrentInit(currentPoint));
                  }}
                >
                  <DarkButtonOnClick text="Visit Website" />
                </div> */}

                {/* <Button
                  className="w-full "
                  onClick={() => {
                    navigate("/fullpage", { replace: true });
                    dispatch(setCurrentInit(currentPoint));
                  }}
                  variant="outlined"
                >
                  View Initative
                </Button> */}
                <Button
                  className="w-full "
                  href={currentPoint.url} target="_blank"
                  variant="outlined"
                >
                  View Website
                </Button>


              </div>
            </div>
          </div>
        </div>
        <div className="rightInfo">
          <div className="">
            <div className="secSection">
              <p className="itemTest">
                <b className="currentPN"></b>
              </p>
            </div>
            <Divider />

            {/* <div className="bottombar">
              <button className="rightBarBtn">
                <a href={currentPoint.url} target="_blank">
                  Visit Website
                </a>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

function openPopup() {
  let bar2 = document.querySelector(".leaflet-popup-content-wrapper");
  let bar3 = document.querySelector(".leaflet-popup-tip");
  let bar = document.querySelector(".diving");
  bar2.style.visibility = "visible";
  bar3.style.visibility = "visible";
  bar.style.display = "none";
}
export function rigthbarAnimation() {
  let bar = document.querySelector(".diving");
  let bar2 = document.querySelector(".leaflet-popup-content-wrapper");
  let bar3 = document.querySelector(".leaflet-popup-tip");
  if (bar.style.display == "flex") {
    let bar_width = 360;
    bar.style.width = bar_width + "px";
    setTimeout(() => {
      let bar_anim = setInterval(() => {
        bar_width -= 5;
        bar.style.width = bar_width + "px";
        if (bar_width <= 0) {
          clearInterval(bar_anim);
          setTimeout(() => {
            bar.style.display = "none";
            bar2.style.visibility = "visible";
            bar3.style.visibility = "visible";
          }, 10);
        }
        // bar2.style.display='flex'
      });
    });
  } else {
    let bar_width = 0;
    bar.style.width = 0 + "px";
    bar.style.display = "flex";
    setTimeout(() => {
      let bar_anim = setInterval(() => {
        bar_width += 5;
        bar.style.width = bar_width + "px";
        if (bar_width >= 350) {
          clearInterval(bar_anim);
        }
      });
    }, 10);
  }
}
function animateButton(buttonID) {
  const button = document.getElementById(buttonID);
  if (button.style.display == "block") {
    let filter_width = 350;
    button.style.width = filter_width + "px";
    setTimeout(() => {
      let filter_anim = setInterval(() => {
        filter_width -= 5;
        button.style.width = filter_width + "px";
        if (filter_width <= 0) {
          clearInterval(filter_anim);
          setTimeout(() => {
            button.style.display = "none";
          }, 10);
        }
      });
    });
  } else {
    let filter_width = 0;
    button.style.width = 0 + "px";
    button.style.display = "block";
    setTimeout(() => {
      let filter_anim = setInterval(() => {
        filter_width += 5;
        button.style.width = filter_width + "px";
        if (filter_width >= 350) {
          clearInterval(filter_anim);
        }
      });
    }, 10);
  }
}

export default FullPage;

import React from "react";

import ThreeDRotation from '@mui/icons-material/ThreeDRotation';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DashItem from './DashItem';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
const MenuBar=()=>{
    return(
        <div className="side-bar">
            <div className="side-bar-logo">
            </div>
            <DashItem Icon={HomeOutlinedIcon} title="Home"/>
            <DashItem Icon={FileUploadOutlinedIcon} title="About the Project"/>
            <DashItem Icon={AddLocationOutlinedIcon} title="Visit Us"/>
            <DashItem Icon={LocationCityOutlinedIcon} title="Sign In"/>
        </div>
    )
    }
export default MenuBar;
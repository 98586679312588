import { useSelector, useDispatch } from "react-redux";
import { setCurrentInit } from "../pages/reducers";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

const GridItem = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(true);

  const currentInit = useSelector((state) => state.currentInit);

  // console.log(props.data.country_name.charAt(0).toUpperCase())
  function capitalizeFirstLetter(str) {
    const excludeWords = ["and"];

    return str
      .toLowerCase()
      .split(" ")
      .map((word) => {
        if (excludeWords.includes(word)) {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  let countryName = props.data.country_name;
  let capitalizedCountryName = capitalizeFirstLetter(countryName);
  // console.log(capitalizedCountryName)

  return (
    <div className="border border-solid border-gray-300 rounded-lg bg-white flex flex-col h-full">
      <div className="">
        {props.status === "ONGOING" || props.status === "Ongoing" || props.status === "ongoing"? (
          <p className="rounded-tl-lg rounded-tr-lg bg-ongoing-project-yellow p-2 text-white font-semibold text-center">
            {props.status.toUpperCase()}
          </p>
        ) : props.status === "COMPLETE" || props.status === "Complete" || props.status === "complete"? (
          <p className="rounded-tl-lg rounded-tr-lg bg-green-500 p-2 text-white font-semibold text-center">
            {props.status.toUpperCase()}
          </p>
        ) : props.status === "PAUSED" ? (
          <p className="rounded-tl-lg rounded-tr-lg bg-ongoing-project-yellow p-2 text-white font-semibold text-center">
            {props.status.toUpperCase()}
          </p>
        ) : (
          <p></p>
        )}
      </div>

      <div className="w-full">
        {props.data.image_id != null ? (
          <img className="projectImageUrls" src={props.project_img}></img>
        ) : (
          <img
            className="projectImageUrls"
            src={require("../Images/default_image.jpeg")}
          ></img>
        )}
      </div>
      <Divider />
      <div className="p-2 ">
        <div className="h-full flex flex-col">
          <p className="text-lg font-bold">{props.name}</p>
          {/* <div class="grid-item">
        <div className="topPopUp" id={props.color}>
          {" "}
          {props.status}
        </div>
        <div className="projectsImg">
          {props.data.image_id != null ? (
            <img className="projectImageUrls" src={props.project_img}></img>
          ) : (
            <img
              className="projectImageUrls"
              src={require("../Images/default_image.jpeg")}
            ></img>
          )}
        </div>
        <div className="initiative-name">
          <h2 className="projNames">{props.name}</h2>
        </div>
        <div>
          <p className="sdgs">
            SDGs:{" "}
            {JSON.parse(props.sdg).map((sdg, index) => {
              return index === 0 ? sdg : `, ${sdg}`;
            })}
          </p>
        </div>
        <br></br>
        <div>
          <p className="locatedInit">
            {" "}
            Located: {capitalizedCountryName}, {props.address}
          </p>
        </div>
        <br></br>
        <div className="Desc ">
          <p className="popupDescs overflow-y-hidden"> {props.desc}</p>
        </div>
        <div
          className="project-read-more"
          onClick={() => {
            navigate("/fullpage", { replace: true });
            dispatch(setCurrentInit(props.data));
          }}
        >
          Read More
        </div>
      </div> */}
          <p className="">
            SDGs:{" "}
            {JSON.parse(props.sdg).map((sdg, index) => {
              return index === 0 ? sdg : `, ${sdg}`;
            })}
          </p>
          <p className="">
            Located: {capitalizedCountryName}, {props.address}
          </p>
          <p className="truncate">{props.desc}</p>
          <div className="mt-auto">
          <Button
            className="w-full "
            onClick={() => {
              navigate("/fullpage", { replace: true });
              dispatch(setCurrentInit(props.data));
            }}
            variant="outlined"
          >
            Read More
          </Button></div>
          {/* {console.log(props)} */}
        </div>
      </div>
    </div>
  );
};
export default GridItem;

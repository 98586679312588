import { useState } from "react";
import Map from "./Map";
import Navbar from '../Components/navbar';
import Footer from '../Components/Footer';
import {toast} from 'react-toastify'
import Overlay from "../Components/overlay";
import Faq from "../Components/Faq";
import MappingInitiative from "../Components/mapIT";
import Secbar2 from '../Components/secbar2';
import { useNavigate } from "react-router-dom";
import Sidebar2 from'../Components/sidebar2'
import FullPage from "../Components/rightBar";
import {useSelector,useDispatch} from 'react-redux'
import { useEffect } from "react";
import { setCurrentPoints } from "./reducers";
import SDG from "../Components/sdg";
import TokenExpirationPopup from "../Components/tokenPopup";
import MenuBar from "../Components/hamburgermenu";
import { GET_PROJECTS_URL } from "../apiEndpoints";
import logoutUser from "../Components/logoutUser";




let popupLc=localStorage.getItem('popup')
export default function HomePage() {
  let dispatch=useDispatch()
  let isAuthenicated=useSelector(state=>state.User)
  const [csvToArray, setCsvToArray] = useState([]);

  // const expirationTime2 = new Date().getTime() + 1 * 60 * 1000; // Set expiration time to 2 minutes from now
  // localStorage.setItem('expirationTime', expirationTime2);
  const [showInactivityPopup, setShowInactivityPopup] = useState(false);
  const logged=useSelector(state=>state.isLoggedIn)
  let authTokens = localStorage.getItem('authTokens')
  const navigate=useNavigate()
  
  
  useEffect(()=>{
    const getData=async()=>{ 
     let response= await fetch(GET_PROJECTS_URL,{
            method:'GET',
        })
        let data=await response.json()
        if(response.status===200){
            dispatch(setCurrentPoints(data))

        }else{
           console.log("bugged oout")
        }
  }
  getData() 
 },[])
 const handleBackToLogin = () => {
  // Perform navigation to the login page
  setShowInactivityPopup(false);
  navigate("/login");
};
useEffect(() => {
  let inactivityTimer;
  const inactivityDuration = 30 * 60 * 1000; // 1 minute in milliseconds

  // Function to handle user inactivity
  const handleInactivity = () => {
    setShowInactivityPopup(true);
    logoutUser()
  };

  // Function to reset the inactivity timer
  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(handleInactivity, inactivityDuration);
  };

  // Initialize the timer
  resetInactivityTimer();

  // Attach event listeners to reset the timer on user interaction
  const handleUserActivity = () => {
    resetInactivityTimer();
  };

  window.addEventListener("mousemove", handleUserActivity);
  window.addEventListener("keydown", handleUserActivity);

  // Clear the inactivity timer and remove event listeners on component unmount
  return () => {
    clearTimeout(inactivityTimer);
    window.removeEventListener("mousemove", handleUserActivity);
    window.removeEventListener("keydown", handleUserActivity);
  };
}, []);

useEffect(() => {
  if (showInactivityPopup) {
    // Display the inactivity popup when showInactivityPopup is true
    {logged && toast(<TokenExpirationPopup onBackToLogin={handleBackToLogin} />)};
  }
}, [showInactivityPopup,logged]);


  return (
    <>
      <MenuBar/>

        <Navbar/>
        {/* <Secbar2/> */}
        {isAuthenicated && authTokens&&<Secbar2/>}
      <div className = 'map-bar'>
        <Sidebar2/>
        {!popupLc&&<Overlay/>}     
          <Map csvToArray={csvToArray} />
          
        <FullPage/>
      </div>
      <MappingInitiative/>
      <SDG/>
        <div>
          <Faq/>
        </div>
      <Footer/>
    </>
  );
}



const SDG=()=>{
    return(
        <div className="SDGs-section-home-page">
            <div className="SDGs-section-header">
                <h1 className='text-xl font-semibold'>Sustainable Development Goals</h1>
            </div>
            <div className="SDGs-section-content grid grid-cols-7 justify-center">
                <a href = 'https://sdgs.un.org/goals/goal1' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-01.jpg" /></a>
                <a href = 'https://sdgs.un.org/goals/goal2' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-02.jpg" /></a>
                <a href = 'https://sdgs.un.org/goals/goal3' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-03.jpg" /></a>
                <a href = 'https://sdgs.un.org/goals/goal4' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-04.jpg" /></a>
                <a href = 'https://sdgs.un.org/goals/goal5' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-05.jpg" /></a>
                <a href = 'https://sdgs.un.org/goals/goal6' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-06.jpg" /></a>
                <a href = 'https://sdgs.un.org/goals/goal7' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-07.jpg"/></a>
                <a href = 'https://sdgs.un.org/goals/goal8' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-08.jpg"/></a>
                <a href = 'https://sdgs.un.org/goals/goal9' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-09.jpg"/></a>
                <a href = 'https://sdgs.un.org/goals/goal10' ><img alt = 'sdg' className='SDGs-section-tile'src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-10.jpg"/></a>
                <a href = 'https://sdgs.un.org/goals/goal11' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-11.jpg"/></a>
                <a href = 'https://sdgs.un.org/goals/goal12' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-12.jpg"/></a>
                <a href = 'https://sdgs.un.org/goals/goal13' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-13.jpg"/></a>
                <a href = 'https://sdgs.un.org/goals/goal14' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-14.jpg"/></a>
                <a href = 'https://sdgs.un.org/goals/goal15' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-15.jpg"/></a>
                <a href = 'https://sdgs.un.org/goals/goal16' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-16.jpg"/></a>
                <a href = 'https://sdgs.un.org/goals/goal17' ><img alt = 'sdg' className='SDGs-section-tile' src="https://sdgs.un.org/sites/default/files/goals/E_SDG_Icons-17.jpg"/></a>
            </div>
            </div>
    )
}

export default SDG
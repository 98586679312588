import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAuthTokens } from "../pages/reducers";
import { setUser } from "../pages/reducers";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import * as React from "react";
import { useEffect,useState,useRef } from 'react';
import { DarkButton } from "./MUI_Components/button";
import DropdownMenu_Nav from "./MUI_Components/dropdown";
import { useNavigate } from "react-router-dom";



const SignIn = () => {
  return (
    <div>
      <DarkButton text={"Sign In"} url={"/login"} />
    </div>
  );
};
const MyAccount = () => {
  let dispatch = useDispatch();
  return (
    <div>
      <DropdownMenu_Nav/>
      
    </div>
  );
};

const Navbar = () => {
  let Authenicated = useSelector((state) => state.User);
  let logged = useSelector((state)=>state.isLoggedIn)
  let authTokens = localStorage.getItem('authTokens')
  const navigate = useNavigate()

  const handleHomeClick = () => {
    navigate('/', { replace: true });
  };
  const handleInventoryClick = () => {
    navigate('/inventory', { replace: true });
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleAboutProjectClick = () => {
    // Handle the click behavior.
    // If you're on a different page, navigate to the home page and scroll to the section.
    if (window.location.pathname !== "/") {
      navigate("/");
    }

    // Scroll to the "About the Project" section after a short delay (e.g., 100 milliseconds).
    setTimeout(() => {
      scrollToSection("project-section");
    }, 100);
  };

  const handleAboutUsClick = () => {
    // Handle the click behavior.
    // If you're on a different page, navigate to the home page and scroll to the section.
    if (window.location.pathname !== "/") {
      navigate("/");
    }

    // Scroll to the "About the Project" section after a short delay (e.g., 100 milliseconds).
    setTimeout(() => {
      scrollToSection("faq-bdy");
    }, 100);
  };

  return (
    <div className="homePage shadow-md">
      <div className="flex py-2 px-8">
        <div className="flex w-1/3 items-center">
          <div>
            <a href="https://www.uwi.edu/gicsrd/" target="__blank">
              <img className="logo1 w-36" alt="logo" src={require("../Images/logo.png")} />
            </a>
          </div>
          <div>
            <a href="https://claralionelfoundation.org"  target="__blank">
              <img
                alt=""
                className="logo2 w-31"
                src={require("../Images/logo2.png")}
              />
            </a>
          </div>
        </div>

        <div className="flex w-2/3 justify-end items-center gap-4 ">
          <a className="text-lg cursor-pointer"  onClick={handleHomeClick}id="home_nav">
            Home
          </a>
{/* className="cursor-pointer" */}
          <a className="text-lg cursor-pointer" onClick={handleAboutProjectClick} id="about-project">
            About the Project
          </a>


          <a
            className="text-lg nav-bar-link cursor-pointer"
            id="visit-us"
            onClick={handleAboutUsClick}
          >
            Visit Us
          </a>
          <a
            className="text-lg nav-bar-link cursor-pointer"
            id="initiative-inventory"
            onClick={handleInventoryClick}
          >
            Initiative Inventory
          </a>

          {Authenicated && authTokens ? <MyAccount /> : <SignIn/>}
          {/* {!Authenicated && !logged && <SignIn />} */}
        </div>
      </div>
      <div>
        <button className="nav-btn">
          <p class="mobileName"> GICSRD</p>
          <MenuOutlinedIcon
            sx={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
            }}
            onClick={displaybar}
          />
        </button>
      </div>
    </div>
  );
};
function myFunction3() {
  document.getElementById("myDropdown3").classList.toggle("showAdmin");
}
// Close the dropdown menu if the user clicks outside of it
window.onclick = function (event) {
  if (!event.target.matches(".sign-in-btn")) {
    var dropdowns = document.getElementsByClassName("dropdown-content-Admin");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("showAdmin")) {
        openDropdown.classList.remove("showAdmin");
      }
    }
  }
};

function displaybar() {
  document.querySelector(".side-bar").classList.toggle("showAdm");
  window.onclick = function (event) {
    if (!event.target.matches(".nav-btn")) {
      var dropdowns = document.querySelector(".side-bar");
      var i;
      console.log("clicked");
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("showAdm")) {
          openDropdown.classList.remove("showAdm");
        }
      }
    }
  };
}

export default Navbar;

import { memo } from 'react';
import Footer from './Footer';
import Navbar from './navbar';
import NavbarAdmin from './navbarAdmin';
import Secbar2 from './secbar2';
// import gridUpload from './gridUpload';
// import type { FC } from 'react';
import {useSelector,useDispatch} from 'react-redux'
import GridUpload from './gridUpload';
import React, { useState, useMemo,useEffect } from 'react';
import {setNewInits} from '../pages/reducers'

// import resets from '../_resets.module.css';
// import classes from './group449.module.css';
// import { Polygon9Icon } from './Polygon9Icon';
// import { Polygon11Icon } from './Polygon11Icon';
// import { Polygon12Icon } from './Polygon12Icon';


const InitiativeInventory=(props)=>{
  let isAuthenicated=useSelector(state=>state.User)
  
  
  return (
    <>
    <Navbar/>
    {isAuthenicated &&<Secbar2/>}
    <GridUpload/>
    <Footer/>
    </>
  )}
    export default InitiativeInventory;
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import {createStore,combineReducers} from 'redux'
import {BrowserRouter} from 'react-router-dom'
import { initsArray, isLoggedIn } from './pages/reducers';
import { Provider } from 'react-redux';
import {createRoot} from 'react-dom/client';
import { currentPoint } from './pages/reducers';
import { currentlyPlottedPoints } from './pages/reducers';
import { usersArray } from './pages/reducers';
import { SDGs } from './pages/reducers';
import { pageChanges } from './pages/reducers';
import { Email } from './pages/reducers';
import { ProjectID } from './pages/reducers';
import { editUserData } from './pages/reducers';
import { AuthTokens } from './pages/reducers';
import {User} from './pages/reducers'
import { view_inits_changes } from './pages/reducers';
import { currentInit } from './pages/reducers';
import { editInitData } from './pages/reducers';
import { filterCount } from './pages/reducers';
import { sdgNames } from './pages/reducers';
import {view_resources_changes} from './pages/reducers'
import {ResName} from './pages/reducers'
const allReducers=combineReducers({
  isLoggedIn,
  currentPoint,
  currentlyPlottedPoints,
  usersArray,
  initsArray,
  SDGs,
  pageChanges,
  Email,
  editUserData,
  editInitData,
  AuthTokens,
  User,
  view_inits_changes,
  currentInit,
  ProjectID,
  filterCount,
  sdgNames,
  view_resources_changes,
  ResName
})
const store=createStore(allReducers,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
const rootElement=document.getElementById('root')
const root=createRoot(rootElement)

root.render(
  
  <React.StrictMode>
      <Provider store={store}>
      <App/>
      </Provider> 
  </React.StrictMode>
  
)
/*
ReactDOM.render(

  <React.StrictMode>

      <BrowserRouter>
      <App/>
      </BrowserRouter>
 
  </React.StrictMode>,
  document.getElementById('root')
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


import React from "react";
import { useSelector,useDispatch } from "react-redux";
import axios from "axios";
import { setChanges } from "../pages/reducers";
import { set_view_changes } from "../pages/reducers";
import { DELETE_PERMANENTLY_PROJECT } from "../apiEndpoints";
import {toast} from "react-toastify"
import TokenExpirationPopup from "./tokenPopup";
export default function DeleteInitPopup({closeToast}) {
  let dispatch=useDispatch()
  let project_id=useSelector(state=>state.ProjectID)
  let AuthTokens=useSelector(state=>state.AuthTokens)
  function RemoveComp(){
    
        let test= {'project_id':project_id}
         const getData=async()=>{ 
            let response= await fetch(DELETE_PERMANENTLY_PROJECT,{
              method:'POST',
              headers:{
                  'Content-Type':'application/json',
                  'Authorization':'Bearer '+ String(AuthTokens.access)
              },
              body:JSON.stringify(test)
              })
               let data=await response.json()
               if(response.status===200){
                console.log(data)
                dispatch(set_view_changes())
                closeToast(); // Close the toast when deletion is successful
               }else if(response.status===401){
                  // toast.error('You are unauthorized. Please Log in')
                  toast(<TokenExpirationPopup/>)
               }
         }   
        getData()
    
  }
  const handleYesClick = () => {
    RemoveComp();
  };

  const handleNoClick = () => {
    closeToast(); // Close the toast if the user clicks "No"
  };
  return (
    <div>
      <div className="">
        <div id="">
          <div id="">
            <p className="popUpText">
              Are you sure you want to delete this Initiative?
            </p>
            <p>Clicking YES will remove this Initiative</p>
            <button className="alert_button1" onClick={handleYesClick}>
              Yes
            </button>
            <button className="alert_button2" onClick={handleNoClick}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  
}




import React, { useState } from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/navbar";
import Secbar2 from "../Components/secbar2";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { convertToArrayofObjects } from "../Components/datamangers";
import TokenExpirationPopup from "../Components/tokenPopup";
import { toast } from "react-toastify";
import AddUserPopUp from "../Components/addUserPopup";
import { ADD_USER_URL } from "../apiEndpoints";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";


const AddUser = () => {
  const handleBackToManageUsers = () => {
    // Perform navigation to the login page
    navigate("/manage");
  };
  const handleBackToLogin = () => {
    // Perform navigation to the login page
    navigate("/login");
  };
  let [error, seterror] = useState("");
  let AuthTokens = useSelector((state) => state.AuthTokens);
  let authTokens = localStorage.getItem('authTokens')
  let isAuthenicated = useSelector((state)=>state.User)
  const navigate = useNavigate();
  function AddUserfunc() {
    let fname = document.getElementById("fname").value;
    let lname = document.getElementById("lname").value;
    let email = document.getElementById("email").value;
    let campus_name = document.getElementById("campus").value;
    let roles_title = document.getElementById("usertype").value;
    if (
      fname === "" ||
      email === "" ||
      lname === "" ||
      campus_name === "0" ||
      roles_title === "0"
    ) {
      toast.error("Please ensure there are no empty fields.");
    } else {
      const addUser = async () => {
        let user = new User(
          fname,
          lname,
          email,
          campus_name,
          roles_title,
          "ACTIVE"
        );

        let response = await fetch(ADD_USER_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(AuthTokens.access),
          },
          body: JSON.stringify(user),
        });
        let data = await response.json();
        if (response.status === 200) {
          // navigate('/userCreated', {replace: true});
          console.log(data);
          toast(
            <AddUserPopUp onBackToManageUsers={handleBackToManageUsers} />,
            {
              // Toast options here
            }
          );
        }  else if(response.status === 401){
          toast(<TokenExpirationPopup onBackToLogin={handleBackToLogin}/>)
          navigate('/login',{replace:true})
        }else {
          seterror(data.detail);
        }
      };
      addUser();
    }
  }
  return (
    <div>
      <Navbar />
      {isAuthenicated&&authTokens&&<Secbar2 />}
      <div>
        <div className="lg:px-16 lg:py-4 px-4 py-4">
          <div className="lg:w-1/2">
            <p className="text-xl font-bold">Add User</p>

            <p className="text-text-dark-grey text-lg pb-4">
              Enter the details below necessary to create a new user. Once
              created, a default password will be sent to user after which they
              may log in an change.
            </p>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col">
                <label className="" for="fname">
                  First Name
                </label>
                <input
                  className="rounded-sm border border-gray-300 bg-white p-2"
                  type="text"
                  placeholder="e.g. John"
                  id="fname"
                ></input>
              </div>
              <div className="flex flex-col">
                <label for="lname" className="">
                  Last Name
                </label>
                <input
                  className="rounded-sm border border-gray-300 bg-white p-2 "
                  type="text"
                  placeholder="e.g. Doe"
                  id="lname"
                ></input>
              </div>

              <div className="flex flex-col">
                <label for="email">Email</label>
                <input
                  className="rounded-sm border border-gray-300 bg-white p-2"
                  type="text"
                  placeholder="e.g. johndoe@example.com"
                  id="email"
                ></input>
              </div>

              <div className="flex flex-col">
                <label for="campus">UWI Campus</label>
                <div className="rounded-sm border border-gray-300 bg-white p-2">
                  <select id="campus" className="w-full" name="campus">
                    <option value="0">-select an option-</option>
                    <option value="Mona">Mona</option>
                    <option value="St. Augustine">St. Augustine</option>
                    <option value="Cave Hill">Cave Hill</option>
                    <option value="Five Islands">Five Islands</option>
                    <option value="UWI Center">UWI Centre</option>
                    <option value="Open Campus">Open Campus</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div>
                <label for="utype">User Type</label>
                <div className="rounded-sm border border-gray-300 bg-white p-2">
                  <select id="usertype" className="w-full" name="utype">
                    <option value="0">-select an option-</option>
                    <option value="ADMINISTRATOR">ADMINISTRATOR</option>
                    <option value="DATA CONTRIBUTOR">DATA CONTRIBUTOR</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="w-2/5 w-full">
            <div className="pt-4 flex flex-col lg:flex-row items-center">
              <Button
                sx={{
                  backgroundColor: "#AA1111",
                  "&:hover": {
                    backgroundColor: "#CB1818",
                  },
                }}
                variant="contained"
                disableElevation
                className="w-full lg:w-auto"
                onClick={() => AddUserfunc()}
              >
                <span style={{ textTransform: "none" }}>Add User</span>
              </Button>

              <Link
                href="/manage"
                className="pl-0 lg:pl-2 pt-4 lg:pt-0"
                sx={{ color: "black" }}
                underline="none"
              >
                {"Cancel"}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export function User(
  first_name,
  last_name,
  email,
  campus_name,
  roles_title,
  status
) {
  this.first_name = first_name;
  this.last_name = last_name;
  this.email = email;
  this.roles_title = roles_title;
  this.campus_name = campus_name;
  this.status = status;
}

export default AddUser;

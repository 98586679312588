
// --- (1), (2) & (3): install and import ---
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 40],
    iconAnchor: [5, 40]
});

L.Marker.prototype.options.icon = DefaultIcon;
// --- ---------------------------------- ---
export function Map() {
  // Berlin coordinates
  const position = [18.476223, -77.893890]
  
  

  // --- (6) Create a custom marker ---
  

  return (
    <section className='map-component' >
   
      {/* --- (5) Add leaflet map container --- */}
      <div className='map'>
      <MapContainer center={position} zoom={6} minZoom={6} scrollWheelZoom={true}>
        <TileLayer
        accessToken = '74XNMok8CtkY6OPMWhykcRrDHS8U7ARQWI71U8feEhy4w5mWyP9TQbssyncW3oop'
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.jawg.io/jawg-terrain/{z}/{x}/{y}.png?access-token={accessToken}'
          // --- (7) Alternative map style (attribution and url copied from the leaflet extras website) ---
          // attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
          // url='https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
          // --- -------------------------------------------------------------------------------------- ---
        />
        
          <Marker position={position} icon ={DefaultIcon}>
          <Popup>
            This is Jamaica
          </Popup>
        </Marker>
       
        
      </MapContainer>
        
      {/* --- ---------------------------- --- */}
      </div>
    </section>
  )
}

export function mapper(){
   var x = Number(document.getElementById('lat').value);
   var y = Number(document.getElementById('long').value);
   console.log(x)
   console.log(y)
   
   
}

export default Map;
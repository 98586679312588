import React,{useRef} from "react";
import { DarkButton, DarkButtonLargeExternal } from "./MUI_Components/button";



const MappingInitiative=()=>{
    return (
        <div name="about-project" className="mapping-ini" id="project-section">
            <div className="img-and-info">
                <div className="map-ini-info">
                    <h1 className="text-xl font-semibold">The Caribbean Climate Knowledge Portal</h1>
                    <p>The Caribbean Climate Knowledge Portal (CCKP) showcases climate resilience initiatives being carried out by organizations throughout the Caribbean. Arising out of a partnership between UWI, UCLA's IoES and the Clara Lionel Foundation, this centralised virtual hub is a user-friendly, openly accessible, online knowledge repository that shares a map of climate resilience projects. It is aimed to inform and amplify the impact of initiatives across the region, sorted by Sustainable Development Goals (SDGs), country, and thematic areas. By also including relevant research, community engagement, policy and infrastructure projects, the portal is anticipated to result in the reduction of duplication of efforts and will foster collaboration between organisations. The CCKP will lead to a better understanding of the current efforts toward resilience and help in the identification of the needs/ information gaps across the region, within a few easy clicks. </p>
                    {/* <a href = 'https://www.uwi.edu/gicsrd/'><button className="dark-button-large">Visit the GICSRD Site<img className = 'img-init' alt='navigation'src={require('../Images/Group_120.png')}></img></button></a> */}
                    {/* <DarkButtonLargeExternal text={"Visit the GICSRD Site"+""} url="https://www.uwi.edu/gicsrd/"/> */}
                </div>
                <div className="uwi-campuses-profiles">
                    <div>
                        <a href = 'https://www.cavehill.uwi.edu/home' target="_blank"><img className="campus-profile" src={require('../Images/cavehill.png')} alt="Visit UWI CaveHill Site"></img></a>
                    </div>
                    <div>
                        <a href = 'https://fiveislands.uwi.edu/' target="_blank"><img className="campus-profile" src={require('../Images/fiveislands.png')} alt="Visit UWI Five Islands Site"></img></a>
                    </div>
                    <div>
                        <a href = 'https://www.mona.uwi.edu/' target="_blank"><img className="campus-profile" src={require('../Images/mona.png')} alt="Visit UWI Mona Site"></img></a>
                    </div>
                    <div>
                        <a href = 'https://sta.uwi.edu/' target="_blank"><img className="campus-profile" src={require('../Images/augustine.png')} alt="Visit UWI St. Augustine Site"></img></a>
                    </div>
                    <div>
                        <a href = 'https://www.uwi.edu/' target="_blank"><img className="campus-profile" src={require('../Images/centre.png')} alt="Visit UWI Centre Site"></img></a>
                    </div>
                    <div>
                        <a href = 'https://www.open.uwi.edu/' target="_blank"><img className="campus-profile" src={require('../Images/opencampus.png')} alt="Visit UWI Open Campus Site"></img></a>
                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default MappingInitiative;
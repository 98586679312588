import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setChanges } from "../pages/reducers";
import { setEmail } from "../pages/reducers";
import { useNavigate } from "react-router-dom";
import { setEditUser } from "../pages/reducers";
import ConfirmPopup from "./confirmPopup";
import { toast } from "react-toastify";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from "@mui/icons-material/Key";
import HttpsIcon from "@mui/icons-material/Https";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DeletePopUp from "./deletePopup";
import ActivateUserPopUp from "./userStatusPopUp";

import { CHANGE_PASSWORD_URL, UPDATE_USER_URL,UPDATE_USER_PASSWORD } from "../apiEndpoints";
const Rows = (props) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let AuthTokens = useSelector((state) => state.AuthTokens);
  function ChangePassword(email, firstname, lastname, role) {
    let info = { email: email, firstname: firstname, lastname: lastname, role:role};
    // console.log(info)
    const changePassword = async () => {
      let response = await fetch(
        UPDATE_USER_PASSWORD,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(AuthTokens.access),
          },
          body: JSON.stringify(info),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        console.log(data)
        // document.getElementById('helper5').style.display = 'flex';
        toast(<ConfirmPopup/>);
      } else {
        console.log("bugging");
      }
    };
    changePassword();
  }

  function ActivateAccount(email, status) {
    let info = { email: email, status: status };
    const UpdateAcc = async () => {
      let response = await fetch(
        UPDATE_USER_URL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(AuthTokens.access),
          },
          body: JSON.stringify(info),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        console.log(data);
        dispatch(setChanges());
        toast(<ActivateUserPopUp/>)
      } else {
        console.log("bugged oout");
      }

    };
    UpdateAcc();
  }
  function edituser(data) {
    dispatch(setEditUser(data));
  }
  return (
    <>
      {/* <ConfirmPopup/> */}
      <tr className="text-base">
        <td className="pl-4 py-4">
          <a className="mng" href="">
            {props.lastname},{props.firstname}
          </a>
        </td>
        <td className=" py-4">{props.email}</td>
        <td className="text-center  py-4">{props.role}</td>
        <td className="text-center  py-4">{props.campus}</td>
        <td className="text-center py-4">
          <button className={props.status}>{props.status}</button>
        </td>
        <td className="text-center py-4">
          <div className="rowOptions gap-2">
            {props.status == "INACTIVE" ? (
              <HttpsIcon
                className="cursor-pointer"
                id="btnRows"
                title="Toggle User Status"
                onClick={() => {
                  ActivateAccount(props.email, props.status);
                  
                }}
              />
            ) : (
              <LockOpenIcon
                className="cursor-pointer"
                id="btnRows"
                title="Toggle User Status"
                onClick={() => {
                  ActivateAccount(props.email, props.status);

                }}
                
                
              />
            )}
            <KeyIcon
              className="cursor-pointer"
              id="btnRows"
              title="Change User's Password"
              onClick={() =>
                ChangePassword(props.email, props.firstname, props.lastname, props.role)
              }
            />

            <EditNoteIcon
              className="cursor-pointer"
              title="Edit User"
              onClick={() =>
                edituser(
                  {
                    email: props.email,
                    firstname: props.firstname,
                    lastname: props.lastname,
                    campus: props.campus,
                    usertype: props.role,
                  },
                  navigate("/Edit", { replace: true })
                )
              }
            />
          </div>
        </td>
        <td className="text-center py-4">
          <DeleteIcon
            sx={{ color: "red" }}
            className="cursor-pointer"
            id="btnRows"
            onClick={() => {
              dispatch(setEmail(props.email));
              toast(<DeletePopUp/>,{style:{
                fontSize: "20px",
                padding: "20px",
                borderRadius: "10px",
                width: "300px"
              }
            })
            }}
          />
        </td>
      </tr>
    </>
  );
};

export default Rows;

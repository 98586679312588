import React from "react";
// import axios from 'axios'
import Footer from "../Components/Footer";
import { useState, useRef } from "react";
import Secbar2 from "../Components/secbar2";
import Navbar from "../Components/navbar";
// import csvToJson from 'csvtojson'
import { useSelector } from "react-redux";
import UploadInitPopup from "../Components/uploadInitPopup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"
import { UPLOAD_URL } from "../apiEndpoints";
import TokenExpirationPopup from "../Components/tokenPopup";

const UploadInitiatives = ()=>{
    let navigate=useNavigate()
    const [csvToArray, setCsvToArray] = useState([]);
    const AuthTokens=useSelector(state=>state.AuthTokens)
    const authTokens = localStorage.getItem('authTokens')
    let isAuthenicated=useSelector(state=>state.User)
    let timer = localStorage.getItem('expirationTime')
    const handleBackToInitiatives = () => {
        // Perform navigation to the login page
        navigate("/posted");
      };
      const handleBackToLogin = () => {
        // Perform navigation to the login page
        navigate("/login");
      };
      const fileInputRef = useRef(null);
      
      async function send() {
        const formData = new FormData();
        const files = document.getElementById("files").files;
      
        // Assuming the first file is a CSV file
        const csvFile = files[0];
      
        // Read the CSV file content
        const csvContent = await csvFile.text();
      
        // Trim whitespace from each field in the CSV
        const trimmedCsvContent = csvContent
          .split('\n')
          .map(row =>
            row
              .split(',')
              .map(field => field.trim())
              .join(',')
          )
          .join('\n');
      
        // Create a Blob with the trimmed CSV content
        const trimmedCsvBlob = new Blob([trimmedCsvContent], { type: 'text/csv' });
      
        // Append the Blob to the FormData
        formData.append("file", trimmedCsvBlob, csvFile.name);
      
        try {
          const response = await fetch(UPLOAD_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${AuthTokens.access}`
            },
            body: formData
          });
          
      
          if (response.status === 200 && authTokens) {
            const data = await response.json();
            toast(<UploadInitPopup onBackToInitiatives={handleBackToInitiatives} />);
          } else if (response.status === 400 && authTokens) {
            const errorData = await response.json();
            console.log(errorData);
            toast(
              <div>
                {errorData.errors.map((error, index) => (
                  <p key={index}>
                    {error.project_name}: {error.errors.join(' ')}
                  </p>
                ))}
              </div>,
              {
                type: 'error',
                style: {
                  fontSize: '10px',
                  padding: '10px',
                  borderRadius: '10px',
                  width: '300px',
                  // Add other CSS properties here as needed
                },
              }
            );
          } else if (response.status === 401) {
            toast(<TokenExpirationPopup onBackToLogin={handleBackToLogin} />);
            navigate('/login', { replace: true });
          }
        } catch (error) {
          console.error("An error occurred:", error);
          // toast('An error occurred while uploading the file', { type: 'error' });
        } finally {
          fileInputRef.current.value = "";
        }
      }
      
      
    return(
        
        <div>
            <Navbar/>
            {isAuthenicated && authTokens && <Secbar2/>}
            <body>
                <div className = 'up-init'>
                    <h1 className = 'init text-xl font-semibold'>
                        Upload Initiatives
                    </h1>
		<p className = 'p-init'>
		   <b>Upload a CSV file</b>,<br></br> upon succesful upload the uploaded <br></br> initiatives will be available for viewing on the map.
                        </p>
                </div>
                <div className = 'up-drag-img'>
                <img src={require('../Images/drag.png')} alt= '' />
                    <input
                        type="file"
                        name='afile'
                        id="files"
                        accept=".csv"
                     onChange={send}
                     ref={fileInputRef}
                     className="hidden"/>
                    <label for="files" id="file-label">Select file</label>
                </div>
                <div className='mobileAdd'>
                    <button className = 'mobileUpload'>
                        Upload
                    </button>
                    <button className = 'mobileButton'>
                        Clear
                    </button>
                </div>
                <div>
                </div>
            </body>
           
            <br></br>
          
            <Footer/>
        </div>
        
    )
}


export default UploadInitiatives;

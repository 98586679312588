import { Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BASE_API_URL, DELETE_PROJECT_RESOURCE } from "../apiEndpoints";
import { useDispatch, useSelector } from "react-redux";
import { setResName, set_view_resources_changes } from "../pages/reducers";
import DeleteResourcePopup from "../Components/deleteResourcePopup";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";

const TableRows = (props) => {
  const dispatch = useDispatch();
  let AuthTokens = useSelector((state) => state.AuthTokens);

  return (
    <tr className="tableRows">
      <td className="pl-4">{props.name}</td>
      <td className="pl-4">{props.dates}</td>
      <td className="pl-2">
        <div className="flex gap-2">
          {" "}
          <a
            className="download-csv1"
            href={BASE_API_URL + props.path}
            target="__blank"
          >
            Download
          </a>
          {props.showDeleteButton && (
            <DeleteIcon  className="download-csv1"
            target="__blank"
            onClick={() => {
              dispatch(setResName(props.name));
              toast(<DeleteResourcePopup />, {
                style: {
                  fontSize: "20px",
                  padding: "20px",
                  borderRadius: "10px",
                  width: "300px",
                },
              });
            }} />
          )}
        </div>
      </td>
    </tr>
  );
};
export default TableRows;

import React from "react";

export default function RemoveFromMapPopUp({ action, closeToast }) {
  const handleClose = () => {
    closeToast();
  };

  const popupText = action === "remove" ? "The project has been removed from map" : "The project has been added to the map";

  return (
    <div>
      <div className="">
        <div id="">
          <div id="">
            <p className="popUpText">{popupText}</p>
            <button className="alert_button" onClick={handleClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Sidebar from "./sidebar";
import PublicIcon from '@mui/icons-material/Public';
import TuneIcon from '@mui/icons-material/Tune';
import { DarkButtonOnClick } from "./MUI_Components/button";

const Popup1=()=>{
    return(
            <span id="popup"className="p-8 rounded-sm bg-white shadow-md text-center">
               <div className="pb-6"> <p className="text-xl font-bold pb-2">How it works</p>
                <span className="flex gap-2">
                    <span className="rounded-lg border border-gray-300 bg-white p-2">
                        <PublicIcon style={{ fontSize: '42px' }} />
                        <p className="pt-4 text-lg font-bold pb-1">View World Wide Initiatives</p>
                        <p className="text-base text-text-dark-grey max-w-[300px] overflow-x-auto">
                            Search for initiatives all over the world using the search filter. Keywords such as Mona, St.Augustine may be used.
                        </p>
                    </span>
                    <span className="rounded-lg border border-gray-300 bg-white p-2">
                        <TuneIcon  style={{ fontSize: '42px' }} />
                        <p className="pt-4 text-lg font-bold">Filter by features</p>
                        <p className="text-base text-text-dark-grey max-w-[300px] overflow-x-auto">
                            Filter the map to display initiatives based on SDGs, campuses, themes, countries and more.
                        </p>
                    </span>
                </span>
                </div>
                <div onClick={pop_btn_clicked}>
                    <DarkButtonOnClick  text={"I'm ready!"} />
                </div>
                
            </span>
    )
}

//const pop_up_btn=document.querySelector(".pop-up-btn")
function pop_btn_clicked(){
    localStorage.setItem('popup',"happened")
    document.getElementById("popup").style.display = "none";
    document.querySelector('.overlay').style.display="none"
    
    // let opvalue=1
    // const anim=setInterval(()=>{
    //     opvalue-=0.01
    //     document.querySelector('.box').style.opacity=`${opvalue}`
    //     document.querySelector('.overlay').style.opacity=`${opvalue}`
    //     if(opvalue<=0){
    //         clearInterval(anim)
    //         document.querySelector('.box').style.display='none'
    //         document.querySelector('.overlay').style.display="none"
    //     }
    // },10)
}

export default Popup1
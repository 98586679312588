import { Route, Routes, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { usersArray } from '../pages/reducers';
import { useSelector } from 'react-redux';
const PrivateRoute = ({ children}) => {
    const user=useSelector(state=>state.User);
    if (user) {
      return children
    }
    return <Navigate to="/login" replace={true} />
  }
export default PrivateRoute